<template>
  <div class="faq-dropdown" @click="isOpen = !isOpen">
    <div class="faq-dropdown-title">
      <div class="faq-dropdown-title-text">{{ title }}</div>
      <div v-if="!isOpen" class="faq-dropdown-open">+</div>
      <div v-else class="faq-dropdown-open">-</div>
    </div>
    <div class="faq-dropdown-desc" v-if="isOpen" v-html="desc"></div>
  </div>
</template>
<script>
export default {
  name: "FaqDropdown",
  props: {
    title: {
      type: String,
      default: () => "",
    },
    desc: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
<style lang="scss">
.faq-dropdown {
  cursor: pointer;
  &-title {
    text-transform: uppercase;
    font-size: 25px;
    border-bottom: 1px solid #2b2b2b;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    &-text {
      max-width: 90%;
    }
  }
  &-open {
    margin-left: auto;
    font-size: 30px;
    height: 38px;
    margin-right: 15px;
  }
  &-desc {
    margin-top: 20px;
    color: #2b2b2b;
  }
}
</style>
