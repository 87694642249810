<template>
  <div class="faq">
    <div class="faq-container">
      <div class="faq-title">
        {{ $t("faqTitle") }}
      </div>

      <div class="faq-items">
        <div class="faq-item">
          <div class="faq-item-icon">
            <img src="@/assets/ship.png" />
          </div>
          <div class="faq-item-title">{{ $t("faqShip") }}</div>
          <div class="faq-item-desc">{{ $t("faqShipDesc") }}</div>
        </div>
        <div class="faq-item">
          <div class="faq-item-icon">
            <img src="@/assets/customized.png" />
          </div>
          <div class="faq-item-title">{{ $t("faqCustomized") }}</div>
          <div class="faq-item-desc">{{ $t("faqCustomizedDesc") }}</div>
        </div>
        <div class="faq-item">
          <div class="faq-item-icon">
            <img src="@/assets/adapter.png" />
          </div>
          <div class="faq-item-title">{{ $t("faqAdapted") }}</div>
          <div class="faq-item-desc">{{ $t("faqAdaptedDesc") }}</div>
        </div>
        <div class="faq-item">
          <div class="faq-item-icon">
            <img src="@/assets/warinaty.png" />
          </div>
          <div class="faq-item-title">{{ $t("faqWarranty") }}</div>
          <div class="faq-item-desc">{{ $t("faqWarrantyDesc") }}</div>
        </div>
        <div class="faq-item">
          <div class="faq-item-icon">
            <img src="@/assets/remote.png" />
          </div>
          <div class="faq-item-title">{{ $t("faqRemote") }}</div>
          <div class="faq-item-desc">{{ $t("faqRemoteDesc") }}</div>
        </div>
        <div class="faq-item">
          <div class="faq-item-icon">
            <img src="@/assets/energy.png" />
          </div>
          <div class="faq-item-title">{{ $t("faqEnergy") }}</div>
          <div class="faq-item-desc">{{ $t("faqEnergyDesc") }}</div>
        </div>
      </div>
      <div class="faq-questions">
        <div class="faq-questions-left">{{ $t("faqQuestions") }}</div>
        <div class="faq-questions-right">
          <FaqDropdown :title="$t('faqHang')" :desc="$t('faqHangDesc')" />
          <FaqDropdown :title="$t('faqPlug')" :desc="$t('faqPlugDesc')" />
          <FaqDropdown
            :title="$t('faqDelivery')"
            :desc="$t('faqDeliveryDesc')"
          />
          <FaqDropdown :title="$t('faqNeon')" :desc="$t('faqNeonDesc')" />
          <FaqDropdown
            :title="$t('faqWarrantyHow')"
            :desc="$t('faqWarrantyHowDesc')"
          />
          <FaqDropdown :title="$t('faqOutside')" :desc="$t('faqOutsideDesc')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FaqDropdown from "@/components/FaqDropdown.vue";

export default {
  name: "FAQ",
  components: { FaqDropdown },
};
</script>
<style lang="scss">
.faq {
  &-container {
    height: 200px;
    width: 1200px;
    margin: 0 auto;
  }
  &-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    margin-top: 70px;
    justify-content: center;
  }
  &-title {
    text-align: center;
    font-size: 35px;
    font-family: "Buttercup", sans-serif;
  }
  &-item {
    border: 1px solid rgb(235 235 235);
    border-radius: 5px;
    padding: 30px;
    text-align: center;
    font-family: "Buttercup", sans-serif;
    width: 320px;
    min-height: 300px;
    box-sizing: border-box;
    &-title {
      font-size: 24px;
      margin-top: 20px;
    }
    &-desc {
      font-size: 16px;
      margin-top: 15px;
    }
  }
  &-img {
    width: 660px;
    margin-left: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-questions {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    padding-bottom: 200px;
    &-left {
      font-size: 45px;
      max-width: 30%;
      padding-left: 120px;
      font-family: "Buttercup", sans-serif;
      color: rgb(9 9 9);
    }
    &-right {
      width: 50%;
      padding-top: 10px;
    }
    .faq-dropdown:not(:first-child) {
      margin-top: 20px;
    }
  }
}
</style>
