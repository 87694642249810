import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "@/assets/css/style.scss";
import router from "@/router";
import { createI18n } from "vue-i18n";
import messages from "@/i18n";
const i18n = createI18n({ locale: "eng", messages });
import api from "@/api/index";
import VueYandexMetrika from "vue3-yandex-metrika";

createApp(App)
  .use(store)
  .use(api)
  .use(i18n)
  .use(router)
  .use(VueYandexMetrika, {
    id: 97901587,
    env: process.env.NODE_ENV,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    },
  })
  .mount("#app");
