<template>
  <HeaderComponent />
  <div class="cart-page">
    <div class="cart-page-title">
      {{ $t("shopingCart") }}
    </div>
    <div class="cart-page-container">
      <div class="cart-page-cart">
        <div class="cart-page-cart-header">
          <div class="cart-page-cart-header-item">
            {{ $t("item") }}
          </div>
          <div class="cart-page-cart-header-price">
            {{ $t("price") }}
          </div>
          <div class="cart-page-cart-header-qty">{{ $t("qty") }}</div>
          <div class="cart-page-cart-header-subtotal">
            {{ $t("subtotal") }}
          </div>
        </div>
        <div class="cart-page-cart-container" v-if="cart.length > 0">
          <div
            class="cart-page-cart-items"
            v-for="(item, index) in cart"
            :key="index"
          >
            <div class="cart-page-cart-items-edit" @click="editItem(item)">
              <i class="fa-solid fa-edit"></i>
            </div>
            <div class="cart-page-cart-items-delete" @click="deleteItem(item)">
              <i class="fa-solid fa-xmark"></i>
            </div>
            <div
              :class="[
                'cart-page-cart-item',
                `cart-page-cart-item-${item.color}`,
              ]"
              :style="`font-family: ${item.font}`"
              v-html="item.text"
              ref="itemText"
            ></div>
            <div class="cart-page-cart-item-desc">
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("led") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ $t("customText") }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("customText") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.text }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("size") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.options.size }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("height") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{
                    item.customSize
                      ? item.customSize.height + " cm"
                      : item.options.height
                  }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("len") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{
                    item.customSize
                      ? item.customSize.length + " cm"
                      : item.options.len
                  }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("colorSmall") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.color }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("fontSmall") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.font }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("usage") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.usage }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("board") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.board }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("wallMounting") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.advanced.wallMounting ? "Yes" : "No" }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("signHanging") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.advanced.signHanging ? "Yes" : "No" }}
                </div>
              </div>
              <div class="cart-page-item-desc-block">
                <div class="cart-page-cart-item-desc-label">
                  {{ $t("designRequested") }}
                </div>
                <div class="cart-page-cart-item-desc-value">
                  {{ item.advanced.design ? "Yes" : "No" }}
                </div>
              </div>
            </div>
            <div class="cart-page-cart-item-price-container">
              <div class="cart-page-cart-item-price">
                <div v-if="!item.customSize">
                  {{
                    curVal === 0
                      ? item.priceUSD + " $"
                      : curVal === 1
                      ? item.priceCZK + " Kč"
                      : item.priceEUR + " €"
                  }}
                </div>
              </div>
              <div class="cart-page-cart-item-qty">
                <div class="cart-page-cart-item-qty-input">
                  <div
                    class="cart-page-cart-item-qty-input-minus"
                    @click="minusQty(index)"
                  >
                    -
                  </div>
                  <input
                    @change="changeCount(index)"
                    v-model="item.count"
                    type="number"
                  />
                  <div
                    class="cart-page-cart-item-qty-input-plus"
                    @click="plusQty(index)"
                  >
                    <div>+</div>
                  </div>
                </div>
              </div>
              <div
                class="cart-page-cart-item-price cart-page-cart-item-price-subtotal"
                v-if="!item.customSize"
              >
                <div>
                  {{
                    curVal === 0
                      ? item.priceUSD * item.count + " $"
                      : curVal === 1
                      ? item.priceCZK * item.count + " Kč"
                      : item.priceEUR * item.count + " €"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-page-actions">
        <div class="cart-page-actions-item cart-page-actions-title">
          {{ $t("orderInfo") }}
        </div>
        <div class="cart-page-actions-footer-mobile">
          <div class="cart-page-actions-footer-text">
            {{ $t("orderTotal") }}
          </div>
          <div class="cart-page-actions-footer-amount">
            {{ price ? price + " " + valutes[curVal] : "-" }}
          </div>
        </div>
        <div class="cart-page-actions-item cart-page-actions-dropdown">
          <div class="cart-page-actions-dropdown-input">
            <div class="cart-page-actions-dropdown-input-text">
              {{ $t("name") }}
            </div>
            <label>
              <input
                :class="{ 'cart-page-actions-input-invalid': !isValid }"
                type="text"
                v-model="name"
              />
            </label>
          </div>
          <div class="cart-page-actions-dropdown-input">
            <div class="cart-page-actions-dropdown-input-text">
              {{ $t("phone") }}
            </div>
            <label>
              <input
                :class="{ 'cart-page-actions-input-invalid': !isValid }"
                type="text"
                v-model="phone"
              />
            </label>
          </div>
          <div class="cart-page-actions-dropdown-input">
            <div class="cart-page-actions-dropdown-input-text">
              {{ $t("email") }}
            </div>
            <label>
              <input
                :class="{ 'cart-page-actions-input-invalid': !isValid }"
                type="email"
                v-model="email"
              />
            </label>
          </div>
          <div class="cart-page-actions-dropdown-input">
            <div class="cart-page-actions-dropdown-input-text">
              {{ $t("address") }}
            </div>
            <label>
              <input
                :class="{ 'cart-page-actions-input-invalid': !isValid }"
                type="text"
                v-model="address"
              />
            </label>
          </div>
        </div>
        <div class="cart-page-actions-footer">
          <div class="cart-page-actions-footer-text">
            {{ $t("orderTotal") }}
          </div>
          <div class="cart-page-actions-footer-amount">
            {{ price ? price + " " + valutes[curVal] : "-" }}
          </div>
        </div>
        <button
          :disabled="cart.length === 0 || price === 0 || fetching || isDisabled"
          class="cart-page-actions-button"
          @click="toOrder"
        >
          {{ $t("completeOrder") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import * as htmlToImage from "html-to-image";
export default {
  name: "CartPage",
  components: { HeaderComponent },
  data() {
    return {
      cart: [],
      valutes: ["$", "Kč", "€"],
      valutesShortCodes: ["USD", "CZK", "EUR"],
      curVal: 0,
      price: 0,
      fetching: false,
      email: "",
      phone: "",
      name: "",
      isValid: true,
      address: "",
      contactId: 0,
      orderNumber: 1,
    };
  },
  async beforeMount() {
    const cart = localStorage.getItem("cart");
    if (!cart) {
      return;
    }
    let curVal = 0;
    switch (this.$i18n.locale) {
      case "eng":
        curVal = 0;
        break;
      case "czk":
        curVal = 1;
        break;
      case "de":
        curVal = 2;
        break;
    }
    this.curVal = curVal;
    this.cart = JSON.parse(cart);
  },
  methods: {
    minusQty(index) {
      const count = this.cart[index].count;
      if (count === 0 || count <= 1) return;
      this.cart[index].count = this.cart[index].count - 1;
      this.countPrice();
    },
    plusQty(index) {
      this.cart[index].count = this.cart[index].count + 1;
      this.countPrice();
    },
    changeCount(index) {
      const count = this.cart[index].count;
      if (count === 0 || count <= 1) {
        this.cart[index].count = 1;
      }
      this.countPrice();
    },
    editItem(item) {
      this.$store.dispatch("setEditableItem", item);
      this.$router.push("/");
    },
    deleteItem(item) {
      this.cart = this.cart.filter((el) => el !== item);
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.countPrice();
    },
    countPrice() {
      let cost = 0;
      this.cart.forEach((el) => {
        switch (this.curVal) {
          case 0:
            cost += el.priceUSD * el.count;
            break;
          case 1:
            cost += el.priceCZK * el.count;
            break;
          case 2:
            cost += el.priceEUR * el.count;
            break;
        }
      });
      this.price = cost;
    },
    async toOrder() {
      this.countPrice();
      if (
        this.name.length === 0 ||
        this.phone.length === 0 ||
        this.email.length === 0
      ) {
        this.isValid = false;
        return;
      } else {
        this.isValid = true;
      }
      this.fetching = true;
      await this.$api.sendpulse.auth();
      let templateId = "";
      await this.$api.sendpulse.getTemplates().then((data) => {
        console.log("data: ", data.data);
        console.log("template " + `${this.$i18n.locale.toUpperCase()} Order`);
        templateId =
          data.data.find(
            (el) => el.name === `${this.$i18n.locale.toUpperCase()} Order`
          ).id || "";
      });
      let items = this.$refs.itemText;
      let item = {};
      item["image"] = items[0];
      item[
        "image"
      ].style = `width: 150px; height: 300px; font-family: ${this.cart[0].font}; font-size: 30px;`;
      // console.log("items[0]", items[0]);
      htmlToImage
        .toBlob(item["image"])
        .then(async (dataUrl) => {
          console.log("dataUrl", dataUrl);
          let reader = new FileReader();
          reader.readAsDataURL(dataUrl);
          let image;
          reader.onloadend = async () => {
            image = reader.result;
            console.log("Image: ", image);
            await this.addToCrm().then(async () => {
              await this.$api.sendpulse
                .sendEmail({
                  email: {
                    subject: this.$t("order"),
                    template: {
                      id: templateId,
                      variables: {
                        name: this.name,
                        email: this.email,
                        order: this.orderNumber,
                        createdAt: new Date(Date.now()).toLocaleDateString(
                          this.$i18n.locale === "eng"
                            ? "en-EN"
                            : this.$i18n.locale === "czk"
                            ? "cz-CZ"
                            : "de-DE",
                          { year: "numeric", month: "long", day: "numeric" }
                        ),
                        customText: this.cart[0].text,
                        size: this.cart[0].options.size,
                        height: this.cart[0].options.height,
                        length: this.cart[0].options.len,
                        color: this.cart[0].color,
                        font: this.cart[0].font,
                        board: this.cart[0].board,
                        remoteController:
                          this.cart[0].advanced.remoteController,
                        mounting: this.cart[0].advanced.wallMounting,
                        count: this.cart[0].count,
                        phone: this.phone,
                        customImage: image,
                        countPrice:
                          this.curVal === 0
                            ? this.cart[0].priceUSD * this.cart[0].count + " $"
                            : this.curVal === 1
                            ? this.cart[0].priceCZK * this.cart[0].count + " Kč"
                            : this.cart[0].priceEUR * this.cart[0].count + " €",
                        totalPrice:
                          this.price + " " + this.valutes[this.curVal],
                      },
                    },
                    from: {
                      name: "adsworld",
                      email: "info@adsworld.cz",
                    },
                    to: [
                      {
                        email: this.email,
                        name: this.name,
                      },
                    ],
                  },
                })
                .then(() => {
                  this.fetching = false;
                });
              this.name = "";
              this.phone = "";
              this.email = "";
              this.address = "";
              localStorage.setItem("cart", JSON.stringify([]));
              this.cart = [];
              this.$store.dispatch("setIsOpen", true);
            });
          };
        })
        .catch((err) => {
          console.log("Error when blob image: ", err);
        });
    },
    async createContact() {
      await this.$api.sendpulse
        .createContact({
          firstName: this.name,
          // firstName: this.name,
          phones: [this.phone],
          // phones: [this.phone],
          emails: [this.email],
          // emails: [this.email],
        })
        .then((data) => {
          this.contactId = data.data.data.id || 0;
        });
    },
    async createDeal() {
      const deal = {
        price: this.price,
        currency: this.valutesShortCodes[this.curVal],
        contact: [this.contactId],
        attributes: [
          {
            attributeId: 587156,
            value: "Sign Text",
          },
          // Text
          {
            attributeId: 587157,
            value: this.cart[0].text,
          },
          // Size
          {
            attributeId: 587158,
            value: this.cart[0].options.size,
          },
          // Height
          {
            attributeId: 587159,
            value: this.cart[0].options.height,
          },
          // Length
          {
            attributeId: 587160,
            value: this.cart[0].options.len,
          },
          // Color
          {
            attributeId: 587161,
            value: this.cart[0].color,
          },
          // Font
          {
            attributeId: 587162,
            value: this.cart[0].font,
          },
          // Usage
          {
            attributeId: 587163,
            value: "Indoor",
          },
          // Board
          {
            attributeId: 587164,
            value: this.cart[0].board,
          },
          // Wall installation material for hanging
          {
            attributeId: 587165,
            value: this.cart[0].advanced.wallMounting ? "Yes" : "No",
          },
          // Installation material for hanging
          {
            attributeId: 587166,
            value: this.cart[0].advanced.signHanging ? "Yes" : "No",
          },
          // Design proof requested
          {
            attributeId: 587167,
            value: this.cart[0].advanced.design ? "Yes" : "No",
          },
          // QTY
          {
            attributeId: 587174,
            value: String(this.cart[0].count),
          },
          // Address of delivery
          {
            attributeId: 587176,
            value: this.address || "Test",
          },
          // Remote Controller
          {
            attributeId: 587177,
            value: this.cart[0].advanced.remoteController ? "Yes" : "No",
          },
        ],
      };
      await this.$api.sendpulse.createDeal(deal).then((data) => {
        this.orderNumber = data.data.data.number;
      });
    },
    async addToCrm() {
      await this.$api.sendpulse.checkEmail(this.email).then(async (data) => {
        if (data.data.data.total === 0) {
          await this.createContact();
          await this.createDeal();
        } else {
          this.contactId = data.data.data.list[0].id || 0;
          await this.createDeal();
        }
      });
    },
  },
  async mounted() {
    this.countPrice();
  },
  beforeRouteLeave() {
    this.cart = [];
    localStorage.removeItem("cart");
  },
  watch: {
    "$i18n.locale"() {
      let curVal = 0;
      switch (this.$i18n.locale) {
        case "eng":
          curVal = 0;
          break;
        case "czk":
          curVal = 1;
          break;
        case "de":
          curVal = 2;
          break;
      }
      this.curVal = curVal;
      this.countPrice();
    },
  },
};
</script>
<style lang="scss">
.cart-page {
  width: 1200px;
  margin: 50px auto;
  &-title {
    font-size: 24px;
    color: #161616;
    font-family: "Avenir Next", sans-serif;
    letter-spacing: 5px;
  }
  &-container {
    display: flex;
    padding-top: 100px;
    gap: 40px;
  }
  &-cart {
    &-header {
      border-bottom: 1px solid #777;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      color: #777;
      font-family: "Avenir Next", sans-serif;
      font-weight: 600;
      &-item {
        width: 400px;
        padding-left: 10px;
      }
      &-price {
        width: 85px;
      }
      &-qty {
        width: 133px;
      }
      &-subtotal {
        width: 131px;
      }
    }
    &-items {
      display: flex;
      padding-top: 10px;
      position: relative;
      &-delete {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        font-family: "Avenir Next", sans-serif;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
      &-edit {
        position: absolute;
        right: 40px;
        top: 8px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          color: #555555;
        }
      }
    }
    &-item {
      min-width: 150px;
      padding-top: 50px;
      height: 300px;
      font-size: 30px;
      line-height: 50px;
      text-align: center;
      &-price {
        color: #777;
        font-family: "Avenir Next", sans-serif;
        display: flex;
        align-items: center;
        max-width: 64px;
        word-break: break-word;
        &-subtotal {
          margin-left: 20px;
        }
        &-container {
          display: flex;
          align-items: center;
        }
      }
      &-qty {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        display: flex;
        align-items: center;
        &-input {
          display: flex;
          align-items: center;
          margin-left: 40px;
          gap: 5px;
          border: 1px solid #000;
          height: 30px;
          &-plus,
          &-minus {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          &-plus {
            border-left: 1px solid #000;
          }
          &-minus {
            border-right: 1px solid #000;
          }
          input {
            border: none;
            outline: none;
            text-align: center;
            width: 30px;
            height: 28px;
          }
        }
      }
      &-desc {
        max-width: 200px;
        margin-left: 40px;
        &-label {
          color: #777;
          margin-top: 10px;
          font-family: "Avenir Next", sans-serif;
        }
        &-value {
          margin-top: 5px;
        }
      }
      &-warm-white {
        color: rgb(255, 253, 207);
      }
      &-white {
        color: rgb(239, 228, 176);
      }
      &-lemon-yellow {
        color: rgb(255, 249, 124);
      }
      &-golden-yellow {
        color: rgb(255, 214, 46);
      }
      &-orange {
        color: rgb(255, 141, 2);
      }
      &-red {
        color: rgb(255, 42, 77);
      }
      &-deep-pink {
        color: rgb(255, 92, 232);
      }
      &-deep-blue {
        color: rgb(2, 116, 252);
      }
      &-tropical-blue {
        color: rgb(36, 183, 222);
      }
      &-deep-green {
        color: rgb(11, 215, 72);
      }
      &-cold-white {
        color: rgb(225, 227, 250);
      }
    }
  }
  &-actions {
    background: #fbfbfb;
    border: 1px solid #ddd;
    padding: 15px;
    width: 373px;
    height: 420px;
    &-title {
      font-size: 20px;
      color: #21293c;
    }
    &-dropdown {
      &-input {
        margin-top: 10px;
        &-text {
          font-family: "Avenir Next", sans-serif;
          font-size: 16px;
          padding-bottom: 10px;
        }
      }
    }
    &-item {
      padding: 10px;
      border-bottom: 1px solid #e7e7e7;
    }
    &-footer {
      font-family: "Avenir Next", sans-serif;
      font-size: 20px;
      color: #777;
      display: flex;
      align-items: center;
      padding: 20px 10px;
      &-amount {
        margin-left: auto;
      }
      &-mobile {
        font-family: "Avenir Next", sans-serif;
        font-size: 20px;
        color: #777;
        display: none;
        align-items: center;
        padding: 20px 10px;
        &-amount {
          margin-left: auto;
        }
      }
    }
    &-button {
      font-family: "Avenir Next", sans-serif;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fa7126;
      color: #fff;
      height: 40px;
      cursor: pointer;
      outline: none;
      border: none;
      width: 100%;
      &:hover {
        opacity: 0.75;
      }
      &:disabled {
        opacity: 0.75;
        cursor: not-allowed;
      }
    }
    &-input {
      &-invalid {
        border: 1px solid red;
      }
    }
  }
}
@media (min-device-width: 320px) and (max-device-width: 1023px) {
  .cart-page {
    &-container {
      flex-wrap: wrap-reverse;
    }
    &-actions {
      width: 100%;
      height: 100%;
      &-title {
        text-align: center;
      }
      &-item {
        font-size: 80px;
      }
      &-dropdown {
        margin-top: 30px;
      }
      &-dropdown-input input {
        width: 90%;
        height: 150px;
        border: 1px solid #000;
      }
      &-dropdown-input-text {
        font-size: 60px;
        text-align: center;
      }
      &-footer {
        display: none;
      }
      &-footer-mobile {
        display: flex;
        font-size: 60px;
      }
      .cart-page-actions-button {
        margin-top: 50px;
      }
      &-button {
        font-size: 50px;
        height: 100px;
      }
    }
    &-title {
      font-size: 100px;
      text-align: center;
    }
    &-cart {
      width: 100%;
      &-header {
        display: none;
      }
      &-items {
        display: block;
        margin-top: 50px;
        &-delete {
          font-size: 120px;
        }
      }
      &-item {
        font-size: 120px;
        text-align: center;
        &-desc {
          font-size: 70px;
          width: 100%;
          max-width: none;
          text-align: center;
          &-label {
            width: 100%;
            margin-top: 90px;
          }
          &-value {
            width: 100%;
          }
        }
        &-price {
          font-size: 80px;
          max-width: none;
          &-container {
            justify-content: center;
            margin-top: 40px;
          }
        }
        &-qty {
          font-size: 70px;
          &-input {
            width: 600px;
            height: 150px;
            font-size: 100px;
            &-minus {
              width: 200px;
              height: 100%;
            }
            &-plus {
              width: 200px;
              height: 100%;
            }
            input {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
