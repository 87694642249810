export default {
  title: "Создайте свой собственный",
  subtitle: "ВАШЕ СОБСТВЕННОЕ ТВОРЕНИЕ, СОЗДАННОЕ ВРУЧНУЮ ИЗ СВЕТА",
  text: "ТЕКСТ",
  font: "ШРИФТ",
  fontSmall: "Шрифт",
  color: "ЦВЕТ",
  colorSmall: "Цвет",
  textarea: "ВВЕДИТЕ ТЕКСТ, НАЖМИТЕ ENTER, ЧТОБЫ ПЕРЕЙТИ НА НОВУЮ СТРОКУ",
  options: "ВЫБЕРИТЕ ВАРИАНТ",
  len: "Длина",
  height: "Высота",
  optsubtitle:
    "*Высота указана в диапазоне. Размеры зависят от стиля шрифта, а также от того, включает ли текст верхний и нижний регистр.",
  indoor: "В помещении",
  note: "*Обратите внимание: на вывеску наносится прозрачное покрытие, которое защищает ее от брызг. Если вывеска будет подвергаться воздействию влажной погоды, пожалуйста, закажите вариант с прозрачным акриловым щитом ниже.",
  backboard: "ВЫБЕРИТЕ ЗАДНЮЮ ПАНЕЛЬ:",
  remoteController: "Контроллер и регулятор яркости",
  wallMounting: "Материал для монтажа на стене",
  mounting: "Крепления",
  extras: "Дополнительно",
  signHanging: "Монтажный материал для подвешивания",
  design: "ЭТО ГОТОВЫЙ ДИЗАЙН?",
  yes: "Да",
  no: "Нет",
  total: "Итого, включая аксессуары:",
  addToCart: "ДОБАВИТЬ!",
  free: "БЕСПЛАТНО",
  designYes: "Запустите мой знак в производство",
  designNo:
    "Я хотел бы получить пробный вариант дизайна, чтобы утвердить его перед изготовлением вывески. Это добавит от 1 до 3 дней к сроку выполнения заказа.",
  customLedSubtitle:
    "Производство и доставка светодиодного неона на заказ занимает около 10-14 дней. Для экспресс-производства, пожалуйста, свяжитесь с нами.",
  chooseFont: "ВЫБЕРИТЕ ШРИФТ",
  chooseColor: "ВЫБЕРИТЕ ЦВЕТ",
  shopingCart: "Корзина",
  item: "Товар",
  price: "Цена",
  subtotal: "Итого",
  summary: "Суммарно",
  totalFor: "Всего",
  completeOrder: "Завершить ваш заказ",
  led: "Индивидуальный дизайн:",
  customText: "Текст подписи",
  size: "Размер",
  usage: "Использование",
  board: "Табло",
  designRequested: "Запрашивается пробный вариант дизайна:",
  orderInfo: "Информация о заказе",
  name: "Имя",
  phone: "Номер телефона",
  email: "Email",
  orderTotal: "Итого",
  modalTitle:
    "Спасибо за ваш запрос. Вы можете увидеть детали заказа в вашем письме.",
  address: "Адрес доставки",
  faqTitle: "Почему стоит выбрать adsworlds?",
  faqShip: "Доставка по всему миру",
  faqShipDesc:
    "Стандартные заказы выполняются в течение 11-14 дней*, включая производство и доставку. Срочные заказы занимают 7-10 дней*, включая производство и доставку по всему миру.",
  faqCustomized: "Неоновые вывески на заказ",
  faqCustomizedDesc:
    "Все наши неоновые световые вывески разрабатываются нами на заказ и могут быть изменены в соответствии с вашими требованиями, размерами и цветами. Мы можем изготовить любую неоновую вывеску по вашему желанию, с любыми шрифтами и широким выбором цветов.",
  faqAdapted: "Адаптер в комплекте",
  faqAdaptedDesc:
    "Ваш новый светодиодный неон поставляется с прозрачным шнуром длиной 2 метра, который подключается к сертифицированному адаптеру. Адаптер имеет дополнительный 3-4-метровый кабель, который подключается к розетке.",
  faqWarranty: "Гарантия 12 месяцев",
  faqWarrantyDesc:
    "Откройте для себя нашу превосходную технологию гибкого светодиодного неона, более прочную и легкую, чем стеклянный неон. Гарантия 12 месяцев распространяется на все неисправные элементы всех наших вывесок.",
  faqRemote: "Доступен пульт дистанционного управления",
  faqRemoteDesc:
    "Украсьте свое пространство с помощью наших светодиодных неоновых ламп. Включите/выключите, выберите одну из 10 настроек яркости и установите идеальную скорость мигания для создания динамичной атмосферы.",
  faqEnergy: "Низкое энергопотребление, высокая яркость",
  faqEnergyDesc:
    "Наши светодиодные неоновые вывески экономичны и экологичны. Они представлены в яркой гамме привлекательных цветов. Они имеют низкое энергопотребление и срок службы 100 000+ часов.",
  faqQuestions: "Часто задаваемые вопросы",
  faqHang: "КАК ПОВЕСИТЬ ВЫВЕСКУ?",
  faqHangDesc:
    "Ваша вывеска - это как красивая картина, но повесить ее еще проще 😎</br>" +
    "У вас есть 2 варианта: </br>" +
    "- Комплект для крепления на стену </br>" +
    "- Комплект для подвешивания </br>" +
    "Если вы не можете установить или повесить свою вывеску, мы также можем предложить вам вариант крепления.",
  faqPlug: "КАК ЕЁ ПОДКЛЮЧИТЬ?",
  faqPlugDesc:
    "Подключите диммер между неоновым светодиодом и блоком питания. Затем подключите блок питания и наблюдайте, как оживает ваш неон!",
  faqDelivery: "КАКОВЫ СРОКИ ДОСТАВКИ?",
  faqDeliveryDesc:
    "Каждое изделие - это 100% ручная работа, поэтому нам нужно время, чтобы создать его по высшему стандарту! Как правило, изготовление и доставка вашего прекрасного изделия занимает от 11 до 14 дней с момента оплаты. В зависимости от спроса мы также предлагаем экспресс-доставку (7-10 рабочих дней) за дополнительную плату, которую можно выбрать во время оформления заказа.</br>" +
    "Если вам нужна табличка к какому-то событию или особой дате, обязательно свяжитесь с нами по адресу <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a>, и мы сделаем все возможное, чтобы доставить вам табличку в срок!",
  faqNeon: "КАК БУДЕТ ВЫГЛЯДЕТЬ МОЙ НЕОН В РЕАЛЬНОЙ ЖИЗНИ?",
  faqNeonDesc:
    "Иногда трудно представить, как будет выглядеть ваша неоновая вывеска в реальности. А может быть, вы не уверены в том, какой цвет вывески вам нужен, и не можете решить, какой будет лучше смотреться в вашем помещении. Не волнуйтесь, наши дизайнеры вышлют вам макет перед окончательной доработкой дизайна, чтобы вы могли убедиться, что ваша вывеска - именно то, что вы хотите!",
  faqWarrantyHow: "НА ЧТО РАСПРОСТРАНЯЕТСЯ ГАРАНТИЯ?",
  faqWarrantyHowDesc:
    "Мы предоставляем гарантию 24 месяца на все наши неоновые светодиодные вывески.</br>" +
    "Однако она не распространяется на повреждения, которые могут возникнуть в результате неправильной установки или физического повреждения во время использования.</br>" +
    "Пожалуйста, свяжитесь с нами, если у вас возникнут какие-либо проблемы с вашими вывесками. Мы сделаем все возможное, чтобы найти лучшее решение для вас!</br>",
  faqOutside: "МОГУ ЛИ Я ПОВЕСИТЬ СВОЮ ВЫВЕСКУ НА УЛИЦЕ?",
  faqOutsideDesc:
    "Нет, эта неоновая вывеска предназначена только для использования внутри помещений, но мы можем сделать ее совместимой с наружной средой, если вы хотите повесить вывеску на улице!</br>" +
    "Сообщите нам об этом по электронной почте <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a> после заказа, так как ваш знак должен быть изготовлен по-другому с использованием специального питания. За это может взиматься дополнительная плата, но наша команда сообщит вам об этом по электронной почте." +
    "Пожалуйста, обратите внимание, что диммеры не могут использоваться на изделиях для наружной установки, и вы всегда должны защищать свою вывеску от экстремальных погодных условий..</br>" +
    "Уличные вывески не должны устанавливаться на металлические конструкции, чтобы избежать превышения максимальной температуры.</br>",
  home: "Главная",
  cart: "Корзина",
  order: "Заказ",
  backboardtxt1: "Акриловое покрытие: Подвесной/настенный монтаж",
  backboardtxt2: "Прямоугольный акрил: Подвесной/настенный монтаж",
  backboardtxt3: "Вырезать по буквам: Подвесной/настенный монтаж",
  backboardtxt4: "Акриловая подставка: Свободно стоящая",
  qty: "Кол-во",
  editMyDesign: "Редактировать мой дизайн",
  details: "Ваши детали:",
};
