export default {
  title: "Create Your Own",
  subtitle: "YOUR OWN CREATION, HAND MADE FROM LIGHT",
  text: "TEXT",
  font: "FONT",
  fontSmall: "Font",
  color: "COLOR",
  colorSmall: "Color",
  textarea: "ENTER TEXT HERE PRESS ENTER TO NEW LINE",
  options: "SELECT YOUR OPTIONS",
  len: "Length",
  height: "Height",
  optsubtitle:
    "*The Height shown is a range. Sizes vary depending on choice of font style, and whether the text includes upper & lower case.",
  indoor: "Indoor",
  outdoor: "Outdoor",
  note: "*Please note: The sign will be treated with a clear seal that will splash-proof it. If the sign will be exposed to wet weather, please order the Clear Acrylic Box backboard option below.",
  backboard: "SELECT YOUR BACK BOARD:",
  remoteController: "Controller & Dimmer",
  wallMounting: "Wall installation material",
  signHanging: "Installation material for hanging",
  mounting: "Mounting",
  extras: "Extras",
  design: "IT'S THE DESIGN READY TO BE MADE?",
  yes: "Yes",
  no: "No",
  total: "Total including accessories:",
  addToCart: "ADD TO CART",
  free: "FREE",
  designYes: "Put my sign straight in to production",
  designNo:
    "I'd like a design proof to approve before my sign is made. Will add 1 to 3 days to the turnaround",
  customLedSubtitle:
    "Custom LED neon production and delivery takes approximately 10-14 days. For express production, please contact us.",
  chooseFont: "CHOOSE FONT",
  chooseColor: "CHOOSE COLOR",
  shopingCart: "Shopping cart",
  item: "Item",
  price: "Price",
  subtotal: "Subtotal",
  summary: "Summary",
  totalFor: "Total for",
  completeOrder: "Complete your order",
  led: "Custom designed:",
  customText: "Sign Text",
  size: "Size",
  usage: "Usage",
  board: "Board",
  designRequested: "Design proof requested:",
  orderInfo: "Order info",
  name: "Name",
  phone: "Phone",
  email: "Email",
  orderTotal: "Order Total",
  modalTitle:
    "Thank you for your request.You can see the order details in your email.",
  address: "Address of delivery",
  faqTitle: "Why Choose adsworlds?",
  faqShip: "Worldwide Shipping",
  faqShipDesc:
    "Standard orders take 11-14 days*, including production and shipping. 'Rush' orders take 7-10 days*, including production and shipping to addresses worldwide.",
  faqCustomized: "Customized Neon Signs",
  faqCustomizedDesc:
    "All of our neon light signs are custom designed by us, and can be altered to your specifications, size and colors. We can make any neon sign you want, in any fonts and a wide selection of colors.",
  faqAdapted: "Adapter Included",
  faqAdaptedDesc:
    "Your new LED neon comes with a 2 metrs transparent cord which plugs into a certified adapter. The adapter has an additional 3-4  metrs of cable that plugs into the socket.",
  faqWarranty: "12-Month Warranty",
  faqWarrantyDesc:
    "Discover our superior LED neon flex technology, stronger and lighter than glass neon. Rest easy with a 12-month warranty covering any faulty items on all our signs.",
  faqRemote: "Remote Control Available",
  faqRemoteDesc:
    "Enhance your space effortlessly with our LED neon lights. Switch ON/OFF, choose from 10 brightness settings, and set the perfect flashing speed for a dynamic atmosphere.",
  faqEnergy: "Low Energy, High Brightness",
  faqEnergyDesc:
    "Our LED neon signs are both economical and ecologically friendly. Come in a vibrant range of eye-catching colors. They have low energy consumption and a 100,000+ hours lifespan",
  faqQuestions: "Frequently Asked Question",
  faqHang: "HOW DO I HANG A SIGN?",
  faqHangDesc:
    "Your sign is like a beautiful painting, but even easier to hang 😎</br>" +
    "You have 2 options: </br>" +
    "- Wall mount kit </br>" +
    "- Hanging kit </br>" +
    "If you can't mount or hang your sign, we can also offer you a mounting option .",
  faqPlug: "HOW DO YOU PLUG IT IN?",
  faqPlugDesc:
    "Connect the dimmer between the neon LED and the power supply. Then connect the power supply and watch your neon come to life!",
  faqDelivery: "WHAT IS OUR DELIVERY TIME?",
  faqDeliveryDesc:
    "Each piece is 100% handmade, so we need some time to create them to the highest standard! Generally, it takes 11 to 14 days from payment to produce and deliver your beautiful piece. Depending on demand, we also offer an express delivery option (7-10 working days) for an additional fee which can be selected at the time of ordering. </br>" +
    "If you need a sign for an event or special date, be sure to contact us at <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a> and we will do everything we can to get your sign to you on time!",
  faqNeon: "WHAT WILL MY NEON LOOK LIKE IN REAL LIFE?",
  faqNeonDesc:
    "Sometimes it's hard to imagine what your neon sign will look like in real life. Or maybe you're not sure what colour sign you'd like, you can't decide which will look best in your space. Don't worry, our designers will send you a mock-up before finalizing the design so you can make sure your sign is exactly what you want!",
  faqWarrantyHow: "WHAT IS COVERED UNDER WARRANTY?",
  faqWarrantyHowDesc:
    "We offer a two-year warranty on all of our neon LED signs.</br>" +
    "However, this does not cover damage that may occur due to improper installation or physical damage during use.</br>" +
    "Please contact us if you have any problems with your signs. We will do our best to find the best solution for you!</br>",
  faqOutside: "CAN I PUT MY SIGN OUTSIDE?",
  faqOutsideDesc:
    "No, this neon sign is for indoor use only, but we can make it compatible with outdoor environments if you want to hang your sign outside!</br>" +
    "Let us know via email at <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a> once you order, as your sign will need to be made differently with special power. Additional charges may apply but our team will let you know via email.</br>" +
    "Please note that dimmers cannot be used on outdoor products and you should always protect your sign from extreme weather conditions.</br>" +
    "Outdoor signs should not be mounted on a metal structure to avoid exceeding the maximum temperature.</br>",
  home: "Home",
  cart: "Cart",
  order: "Order",
  backboardtxt1: "Cut Around Acrylic: Hang/Wall-mount",
  backboardtxt2: "Rectangle Acrylic: Hang/Wall-mount",
  backboardtxt3: "Cut To Letter: Hang/Wall-mount",
  backboardtxt4: "Acrylic Stand: Free-Standing",
  qty: "Qty",
  editMyDesign: "Edit my design",
  details: "Your details:",
};
