export default {
  title: "Navrhni Vlastní Neon",
  subtitle: "VÁŠ VLASTNÍ NÁVRH VYROBENÝ ZE SVĚTLA",
  text: "TEXT",
  font: "PÍSMO",
  fontSmall: "Písmo",
  color: "BARVA",
  colorSmall: "Barva",
  textarea: "vložte text sem Stiskněte enter pro nový řádek",
  options: "VYBERTE SI VARIANTU",
  len: "Délka",
  height: "Výška",
  optsubtitle:
    "*Zobrazená výška je orientační. Velikosti se liší v závislosti na výběru stylu písma a na tom, zda text obsahuje velká a malá písmena.",
  indoor: "Vnitřní",
  note: "*Vezměte prosím na vědomí: LED Neon bude opatřen zalitím spojů, které je ochrání před vlhkem, nikoli deštěm. Pokud bude LED Neon vystaven na dešti, objednejte si níže uvedenou možnost „Průhledný akrylátový box“.",
  backboard: "VYBERTE SI OŘEZ PODKLADU:",
  remoteController: "Ovladač & Stmívač",
  wallMounting: "Instalační materiál na stěnu",
  mounting: "Montáž",
  extras: "Doplňky",
  signHanging: "Instalační materiál na zavěšení",
  design: "JJE NÁVRH PŘIPRAVEN K VÝROBĚ?",
  yes: "Ano",
  no: "Ne",
  total: "Celkem včetně příslušenství:",
  addToCart: "PŘIDAT DO KOŠÍKU",
  free: "ZDARMA",
  designYes: "Dejte nápis do košíku",
  designNo: "Raději bych design ještě schváli před výrobou",
  customLedSubtitle:
    "Výroba a dodání LED neonů na zakázku trvá přibližně 10-14 dní. Pro expresní výrobu nás prosím kontaktujte.",
  chooseFont: "VYBERTE PÍSMO",
  chooseColor: "VYBERTE BARVU",
  shopingCart: "Nákupní košík",
  item: "Položka",
  price: "Cena",
  subtotal: "Mezisoučet",
  summary: "Souhrn",
  totalFor: "Celkem za",
  completeOrder: "Dokončit objednávku",
  led: "LED neon navržený na míru",
  customText: "Text Nápisu",
  size: "Velikost",
  usage: "Podklad",
  board: "Ořez ke tvaru:",
  designRequested: "Je nápis hotový a může do výroby?",
  orderInfo: "Informace o objednávce",
  name: "Název",
  phone: "Telefon",
  email: "Email",
  orderTotal: "Objednávka celkem",
  modalTitle:
    "Děkujeme vám za vaši žádost.Podrobnosti o objednávce můžete prohlížet v e-mailu.",
  address: "Adresa dodání",
  faqTitle: "Proč si zvolit adsworlds?",
  faqShip: "Celosvětová přeprava",
  faqShipDesc:
    "Standardní objednávky trvají 11-14 dní*, včetně výroby a dopravy. Spěšné objednávky trvají 7-10 dní*, včetně výroby a dopravy na adresy po celém světě.",
  faqCustomized: "Neonové nápisy na míru",
  faqCustomizedDesc:
    "Všechny naše neonové světelné nápisy navrhujeme na zakázku a můžeme je upravit podle vašich požadavků, velikosti a barev. Můžeme vyrobit jakýkoli neonový nápis, který si přejete, v jakémkoli písmu a v širokém výběru barev.",
  faqAdapted: "Včetně adaptéru",
  faqAdaptedDesc:
    "Váš nový LED neon je dodáván s 2metrovým průhledným kabelem, který se zapojuje do certifikovaného adaptéru. Adaptér má další 3-4 metry kabelu, který se zapojuje do zásuvky.",
  faqWarranty: "12 měsíční záruka",
  faqWarrantyDesc:
    "Objevte naši vynikající technologii LED neonů flex, které jsou silnější a lehčí než skleněné neony. Buďte v klidu díky 12měsíční záruce, která se vztahuje na všechny vadné položky na všech našich cedulích.",
  faqRemote: "K dispozici je dálkové ovládání",
  faqRemoteDesc:
    "Vylepšete svůj prostor bez námahy pomocí našich neonových světel LED. Zapněte/vypněte, vyberte si z 10 nastavení svítivosti a nastavte ideální rychlost blikání pro dynamickou atmosféru.",
  faqEnergy: "Nízká spotřeba energie, vysoký výkon.",
  faqEnergyDesc:
    "Naše neonové nápisy LED jsou úsporné a ekologické. Dodávají se v živé škále poutavých barev. Mají nízkou spotřebu energie a životnost více než 100 000 hodin.",
  faqQuestions: "Často kladené otázky",
  faqHang: "JAK ZAVĚSIT NÁPIS?",
  faqHangDesc:
    "Vaše nápis je jako krásný obraz, ale ještě jednodušší na zavěšení 😎</br>" +
    "Máte 2 možnosti:</br>" +
    "- Sada pro montáž na zeď</br>" +
    "- Sada pro zavěšení</br>" +
    "Pokud nemůžete nápis namontovat nebo zavěsit, můžeme vám nabídnout i možnost montáže .</br>",
  faqPlug: "JAK JEJ ZAPOJIT?",
  faqPlugDesc:
    "Zapojte stmívač mezi LED neon a napájecí zdroj. Pak připojte napájecí zdroj a sledujte, jak váš neon oživuje!",
  faqDelivery: "JAKÁ JE NAŠE DODACÍ LHŮTA?",
  faqDeliveryDesc:
    `Každý kus je 100% ruční práce, takže potřebujeme nějaký čas, abychom je vytvořili na nejvyšší úrovni! Obecně platí, že výroba a dodání vašeho krásného kousku trvá 11 až 14 dní od zaplacení. V závislosti na poptávce nabízíme také možnost expresního dodání (7-10 pracovních dnů) za příplatek, který lze zvolit při objednávce.</br>` +
    `Pokud potřebujete nápis k nějaké události nebo speciálnímu datu, nezapomeňte nás kontaktovat na adrese <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a> a my uděláme vše pro to, abychom vám nápis dodali včas!</br>`,
  faqNeon: "JAK BUDE MŮJ NEON VYPADAT VE SKUTEČNOSTI?",
  faqNeonDesc:
    "Někdy je těžké si představit, jak bude váš neonový nápis vypadat ve skutečnosti. Nebo si třeba nejste jisti, jakou barvu nápisu byste chtěli, nemůžete se rozhodnout, která bude ve vašem prostoru vypadat nejlépe. Nebojte se, naši designéři vám před dokončením návrhu zašlou maketu, abyste se mohli ujistit, že váš nápis bude přesně takový, jaký chcete!",
  faqWarrantyHow: "NA CO SE VZTAHUJE ZÁRUKA?",
  faqWarrantyHowDesc:
    "Na všechny naše neonové nápisy LED poskytujeme dvouletou záruku.</br>" +
    "Ta se však nevztahuje na poškození, ke kterému může dojít v důsledku nesprávné instalace nebo fyzického poškození během používání.</br>" +
    "V případě jakýchkoliv problémů s vašimi nápisy nás prosím kontaktujte. Uděláme vše pro to, abychom pro vás našli to nejlepší řešení!",
  faqOutside: "MOHU SVŮJ NÁPIS VYVĚSIT VENKU?",
  faqOutsideDesc:
    "Ne, tento neon je pouze pro vnitřní použití, ale můžeme jej vyrobit tak, aby byl kompatibilní s venkovním prostředím, pokud chcete svůj nápis zavěsit venku!</br>" +
    "Dejte nám vědět e-mailem na <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a> jakmile si objednáte, protože váš nápis bude muset být vyroben jinak se speciálním napájením. Mohou být účtovány další poplatky, ale náš tým vám je sdělí prostřednictvím e-mailu.</br>" +
    "Upozorňujeme, že stmívače nelze použít na venkovní výrobky a vždy byste měli svou ceduli chránit před extrémními povětrnostními vlivy.</br>" +
    "Venkovní cedule by neměly být montovány na kovovou konstrukci, aby nedošlo k překročení maximální teploty.",
  home: "Domů",
  cart: "Košík",
  order: "Objednávka",
  backboardtxt1: "Řezání kolem akrylu: Závěsný/nástěnný",
  backboardtxt2: "Obdélníkový akryl: Zavěšení/nástěnná montáž",
  backboardtxt3: "Střih na dopis: Zavěšení/nástěnná montáž",
  backboardtxt4: "Akrylový stojan: Volně stojící",
  qty: "Qty",
  editMyDesign: "Upravit můj návrh",
  details: "Vaše údaje:",
};
