import eng from "./eng";
import czk from "./czk";
import de from "./de";
import ru from "@/i18n/ru";

const messages = {
  eng: eng,
  czk: czk,
  de: de,
  ru: ru,
};

export default messages;
