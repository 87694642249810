<template>
  <div class="header-component">
    <div class="header-component-row">
      <div class="header-component-container header-component-flex">
        <a class="header-component-logo" href="https://adsworld.cz">
          <img src="../assets/logo.png" alt="adsworld.cz" />
        </a>
        <router-link to="/" class="header-component-home">
          <i class="fa fa-home" aria-hidden="true"></i>
        </router-link>
        <div class="header-component-langs">
          <div
            v-for="(item, index) in langs"
            :key="index"
            :class="[
              'header-component-langs-item',
              { 'header-component-langs-item-active': lang === index },
            ]"
            @click="changeLang(item, index)"
          >
            {{ item }}
          </div>
        </div>
        <router-link to="/cart" class="header-component-menu-item">
          <i class="fa fa-cart-shopping"></i>
        </router-link>
      </div>
    </div>
    <div class="header-component-menu">
      <div class="header-component-container header-component-flex"></div>
    </div>
  </div>
  <div class="header-component-mobile">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      id="burger-menu"
      @click="setBurger(!burgerOpen)"
    >
      <path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z" />
      <path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
      <path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
    </svg>
  </div>
  <div
    :class="[
      'full-header',
      {
        'full-header-open': burgerOpen,
        'full-header-close': !burgerOpen,
      },
    ]"
  >
    <a class="full-header-img" href="https://adsworld.cz">
      <img src="../assets/logo2.png" alt="adsworld.cz" />
    </a>
    <div class="full-header-items">
      <div class="full-header-items-item" @click="goTo('/')">
        <i class="fa fa-home" aria-hidden="true"></i>
        <div>{{ $t("home") }}</div>
      </div>
      <div class="full-header-items-item" @click="goTo('/cart')">
        <i class="fa fa-cart-shopping"></i>
        <div>{{ $t("cart") }}</div>
      </div>
    </div>
    <div class="header-component-mobile-langs">
      <div
        v-for="(item, index) in langs"
        :key="index"
        :class="[
          'header-component-mobile-langs-item',
          { 'header-component-langs-item-active': lang === index },
        ]"
        @click="changeLang(item, index)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      lang: 1,
      langs: ["czk", "eng", "de"],
    };
  },
  computed: {
    burgerOpen() {
      return this.$store.getters.getBurger;
    },
  },
  methods: {
    changeLang(lang, index) {
      this.$i18n.locale = lang;
      this.lang = index;
      localStorage.setItem("locale", lang);
    },
    setBurger(value) {
      this.$store.dispatch("setBurger", value);
    },
    goTo(path) {
      this.setBurger(false);
      this.$router.push(path);
    },
  },
  created() {
    const locale = localStorage.getItem("locale");
    if (locale) {
      this.lang = this.langs.indexOf(locale);
    }
  },
};
</script>
<style lang="scss">
.header-component {
  font-family: TildaSansLight, sans-serif;
  &-flex {
    display: flex;
    align-items: center;
  }
  &-container {
    width: 1200px;
    margin: 0 auto;
  }
  &-logo {
    width: 200px;
    padding-top: 23px;
    padding-bottom: 10px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &-row {
    display: flex;
    background: rgb(226, 226, 226);
    align-items: center;
  }
  &-menu {
    font-family: TildaSansBold, sans-serif;
    background: #fa7126;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 20px;
    color: #fff;
    &-item {
      color: #000;
      margin-left: 30px;
      font-size: 20px;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  &-langs {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-left: auto;
    cursor: pointer;
    &-item {
      text-transform: uppercase;
      &:hover {
        font-family: TildaSansBold, sans-serif;
      }
      &-active {
        font-family: TildaSansBold, sans-serif;
      }
    }
  }
  &-home {
    margin-left: 25px;
    font-size: 20px;
    color: #000;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
  &-mobile {
    height: 120px;
    display: none;
    background: #fa7126;
    position: relative;
    &-langs {
      display: flex;
      gap: 50px;
      justify-content: center;
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 80px;
      text-transform: uppercase;
      &-item {
        margin-top: 50px;
      }
    }
  }
}
.full-header {
  width: 100vw;
  height: 100vh;
  background: white;
  position: absolute;
  left: 0;
  top: 250px;
  z-index: 3;
  &-open {
    animation: slide 0.3s ease;
    display: block;
    @keyframes slide {
      0% {
        left: -100vw;
      }
      100% {
        left: 0;
      }
    }
  }
  &-close {
    animation: slide-out 0.3s ease forwards;
    display: none;
    @keyframes slide-out {
      0% {
        display: block;
        left: 0;
      }
      100% {
        left: -100vw;
        display: none;
      }
    }
  }
  &-img {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    border-bottom: 3px solid #000;
    padding-bottom: 40px;
  }
  &-items {
    &-item {
      font-size: 80px;
      display: flex;
      gap: 30px;
      width: 100%;
      height: 200px;
      border-bottom: 3px solid #000;
      text-decoration: none;
      color: #000;
      padding-left: 50px;
      font-family: Buttercup, sans-serif;
      align-items: center;
      .fa {
        font-size: 90px;
      }
    }
  }
}
#burger-menu {
  width: 120px;
  height: 120px;
  margin-left: 40px;
  margin-top: 40px;
  cursor: pointer;
}
@media (min-device-width: 320px) and (max-device-width: 1023px) {
  .header-component {
    display: none;
    &-mobile {
      display: flex;
      position: fixed;
      z-index: 4;
      width: 100%;
      top: 0;
      height: 200px;
    }
  }
  .main-container {
    padding-top: 250px;
  }
}
</style>
