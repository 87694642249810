<template>
  <div class="success-modal">
    <div class="success-modal-title">
      {{ $t("modalTitle") }}
    </div>
    <div class="success-modal-btn" @click="closeModal">Ok</div>
  </div>
</template>
<script>
export default {
  methods: {
    closeModal() {
      this.$store.dispatch("setIsOpen", false);
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss">
.success-modal {
  width: 500px;
  height: 200px;
  background: #fff;
  border: 1px solid #000;
  padding: 20px;
  border-radius: 10px;
  box-shadow: #00000069 10px 10px 10px;
  &-title {
    font-size: 20px;
    text-align: center;
  }
  &-btn {
    margin: 100px auto;
    width: 100px;
    border: 1px solid #fa7126;
    background: #fa7126;
    color: #fff;
    height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    &:hover {
      opacity: 0.75;
    }
  }
}
@media (min-device-width: 320px) and (max-device-width: 1023px) {
  .success-modal {
    top: 25% !important;
    width: 75%;
    height: 700px;
    &-title {
      font-size: 80px;
    }
    &-btn {
      width: 400px;
      height: 130px;
      font-size: 60px;
    }
  }
}
</style>
