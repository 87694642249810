import { createStore } from "vuex";

export default createStore({
  state: {
    isOpen: false,
    burgerOpen: false,
    editableItem: {},
  },
  getters: {
    getIsOpen: (state) => state.isOpen,
    getBurger: (state) => state.burgerOpen,
    getEditableItem: (state) => state.editableItem,
  },
  mutations: {
    SET_IS_OPEN(state, value) {
      state.isOpen = value;
    },
    SET_BURGER(state, value) {
      state.burgerOpen = value;
    },
    SET_EDITABLE_ITEM(state, value) {
      state.editableItem = value;
    },
  },
  actions: {
    setIsOpen({ commit }, value) {
      commit("SET_IS_OPEN", value);
    },
    setBurger({ commit }, value) {
      commit("SET_BURGER", value);
    },
    setEditableItem({ commit }, value) {
      commit("SET_EDITABLE_ITEM", value);
    },
  },
  modules: {},
});
