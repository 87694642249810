export default {
  title: "ONLINE DESIGNER",
  subtitle: "IHR EIGENES DESIGN AUS LICHT",
  text: "TEXT",
  font: "SCHRIFTART",
  fontSmall: "Schriftart",
  color: "FARBE",
  colorSmall: "Farbe",
  textarea:
    "Text hier einfügen Drücken Sie die Eingabetaste für eine neue Zeile",
  options: "WÄHLEN SIE EINE VARIANTE",
  len: "Länge",
  height: "Höhe",
  optsubtitle:
    "*Die angegebene Höhe ist ein Richtwert. Die Größen variieren je nach Schriftart und je nachdem, ob der Text Groß- oder Kleinbuchstaben enthält.",
  indoor: "Innenbereich",
  note: '*Bitte beachten Sie: Das LED Neon wird mit einer wasserdichten Dichtung versehen, um es vor Feuchtigkeit zu schützen, nicht vor Regen. Wenn die LED Neon dem Regen ausgesetzt wird, bestellen Sie bitte die Option "Klare Acrylbox" unten.',
  backboard: "WÄHLEN SIE EINE GRUNDAUSSTATTUNG:",
  remoteController: "Controller & Dimmer",
  wallMounting: "Material für die Wandmontage",
  mounting: "Montage",
  extras: "Extras",
  signHanging: "Installationsmaterial zum Aufhängen",
  design: "IST DER ENTWURF FERTIG?",
  yes: "Ja",
  no: "Nein",
  total: "Insgesamt einschließlich Zubehör:\n",
  addToCart: "In den Korb legen",
  free: "KOSTENLOS",
  designYes: "Setzen Sie mein Zeichen direkt in die Produktion ein",
  designNo:
    "Ich möchte einen Entwurf zur Freigabe, bevor mein Schild hergestellt wird. Wird 1 bis 3 Tage auf die Bearbeitungszeit hinzufügen",
  customLedSubtitle:
    "Die Produktion und Lieferung von kundenspezifischen LED-Neonröhren dauert etwa 10-14 Tage. Für eine Expressproduktion, kontaktieren Sie uns bitte.",
  chooseFont: "WÄHLEN SIE EINEN BUCHSTABEN",
  chooseColor: "FARBE WÄHLEN",
  shopingCart: "Einkaufswagen",
  item: "Artikel",
  price: "Preis",
  subtotal: "Zwischensumme",
  summary: "Zusammenfassung",
  totalFor: "Insgesamt für",
  completeOrder: "Schließen Sie Ihre Bestellung ab",
  led: "Kundenspezifisch gestaltetes LED-Neon",
  customText: "Text LED-Schild",
  size: "Größe",
  usage: "Verwendung",
  board: "Vorstand",
  designRequested: "Ist das Schild fertig und kann es in Produktion gehen?",
  orderInfo: "Infos bestellen",
  name: "Name",
  phone: "Telefon",
  email: "Email",
  orderTotal: "Bestellung Gesamt",
  modalTitle:
    "Vielen Dank für Ihre Bewerbung, die Einzelheiten der Bestellung können Sie in Ihren E-Mails sehen.",
  address: "Adresse der Zustellung",
  faqTitle: "Warum adsworlds wählen?",
  faqShip: "Weltweiter Versand",
  faqShipDesc:
    "Standardbestellungen dauern 11-14 Tage*, einschließlich Produktion und Versand. Eilaufträge dauern 7-10 Tage*, einschließlich Produktion und Versand an Adressen weltweit.",
  faqCustomized: "Maßgeschneiderte Neonschilder",
  faqCustomizedDesc:
    "Alle unsere Neonschilder werden von uns individuell gestaltet und können nach Ihren Vorgaben in Größe und Farbe geändert werden. Wir können jedes Neonschild, das Sie wünschen, in jeder Schriftart und einer großen Auswahl an Farben herstellen.",
  faqAdapted: "Inklusive Adapter",
  faqAdaptedDesc:
    "Ihre neue LED-Leuchte wird mit einem 2 Meter langen transparenten Kabel geliefert, das an einen zertifizierten Adapter angeschlossen wird. Der Adapter hat ein zusätzliches 3-4 Meter langes Kabel, das in die Steckdose gesteckt wird.",
  faqWarranty: "12-Monats-Garantie",
  faqWarrantyDesc:
    "Entdecken Sie unsere überlegene LED-Neon-Flex-Technologie, die stärker und leichter ist als Glasneon. Seien Sie beruhigt, denn wir gewähren auf alle unsere Schilder eine 12-monatige Garantie auf alle fehlerhaften Teile.",
  faqRemote: "Es gibt eine Fernbedienung",
  faqRemoteDesc:
    "Verschönern Sie Ihren Raum mühelos mit unseren Neon-LED-Leuchten. Schalten Sie ein/aus, wählen Sie aus 10 Helligkeitsstufen und stellen Sie die ideale Blinkfrequenz für eine dynamische Atmosphäre ein.",
  faqEnergy: "Niedriger Energieverbrauch, hohe Helligkeit",
  faqEnergyDesc:
    "Unsere LED-Leuchtschriften sind sowohl wirtschaftlich als auch umweltfreundlich. Sie sind in einer Vielzahl von auffälligen Farben erhältlich. Sie haben einen niedrigen Energieverbrauch und eine Lebensdauer von über 100.000 Stunden.",
  faqQuestions: "Häufig gestellte Fragen",
  faqHang: "WIE HÄNGE ICH EIN SCHILD AUF?",
  faqHangDesc:
    "Ihr Schild ist wie ein schönes Gemälde, aber noch einfacher aufzuhängen 😎.</br>" +
    "Sie haben 2 Möglichkeiten:</br>" +
    "- Wandmontageset</br>" +
    "- Aufhängeset</br>" +
    "Wenn Sie Ihr Schild nicht montieren oder aufhängen können, können wir Ihnen auch eine Montageoption anbieten.",
  faqPlug: "WIE SCHLIESST MAN ES AN?",
  faqPlugDesc:
    "Schließen Sie den Dimmer zwischen der Neon-LED und dem Netzteil an. Dann schließen Sie die Stromversorgung an und beobachten Sie, wie Ihr Neon zum Leben erwacht!",
  faqDelivery: "WIE LANGE IST UNSERE LIEFERZEIT?",
  faqDeliveryDesc:
    "Jedes Stück ist zu 100 % handgefertigt, daher brauchen wir etwas Zeit, um es auf höchstem Niveau herzustellen! In der Regel dauert es 11 bis 14 Tage ab Zahlungseingang, um Ihr schönes Stück herzustellen und zu liefern. Je nach Nachfrage bieten wir auch eine Expresslieferung (7-10 Arbeitstage) gegen eine zusätzliche Gebühr an, die Sie bei der Bestellung auswählen können.</br>" +
    "Wenn Sie ein Schild für eine Veranstaltung oder ein besonderes Datum benötigen, kontaktieren Sie uns bitte unter <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a>. Wir werden alles tun, um Ihnen Ihr Schild rechtzeitig zu liefern!",
  faqNeon: "WIE WIRD MEIN NEONSCHILD IN WIRKLICHKEIT AUSSEHEN?",
  faqNeonDesc:
    "Manchmal ist es schwierig, sich vorzustellen, wie Ihr Neonschild in Wirklichkeit aussehen wird. Oder Sie sind sich nicht sicher, welche Farbe Sie haben möchten und können sich nicht entscheiden, welche am besten zu Ihrem Raum passt. Keine Sorge, unsere Designer schicken Ihnen vor der endgültigen Gestaltung ein Mock-up, damit Sie sich vergewissern können, dass Ihr Schild genau so aussieht, wie Sie es sich wünschen!",
  faqWarrantyHow: "WAS IST DURCH DIE GARANTIE ABGEDECKT?",
  faqWarrantyHowDesc:
    "Wir bieten eine zweijährige Garantie auf alle unsere LED-Neonschilder.</br>" +
    "Diese deckt jedoch keine Schäden ab, die durch unsachgemäße Installation oder physische Beschädigung während des Gebrauchs entstehen können.</br>" +
    "Bitte kontaktieren Sie uns, wenn Sie Probleme mit Ihren Schildern haben. Wir werden unser Bestes tun, um die beste Lösung für Sie zu finden!</br>",
  faqOutside: "KANN ICH MEIN SCHILD DRAUSSEN AUFHÄNGEN?",
  faqOutsideDesc:
    "Nein, dieses Neonschild ist nur für den Innenbereich geeignet, aber wir können es für den Außenbereich kompatibel machen, wenn Sie Ihr Schild draußen aufhängen möchten!</br>" +
    "Teilen Sie uns dies nach der Bestellung per E-Mail an <a href='mailto:info{'@'}adsworld.cz'>info{'@'}adsworld.cz</a> mit, da Ihr Schild mit einer speziellen Leistung anders angefertigt werden muss. Es können zusätzliche Kosten anfallen, aber unser Team wird Sie per E-Mail darüber informieren.</br>" +
    "Bitte beachten Sie, dass Dimmer bei Produkten für den Außenbereich nicht verwendet werden können und dass Sie Ihr Schild immer vor extremen Wetterbedingungen schützen sollten.</br>" +
    "Schilder für den Außenbereich sollten nicht auf einer Metallstruktur montiert werden, um eine Überschreitung der maximalen Temperatur zu vermeiden.",
  home: "Startseite",
  cart: "Korb",
  order: "Bestellung",
  backboardtxt1: "Schnitt um Acryl: Hängen/Wandmontage",
  backboardtxt2: "Rechteckiges Acryl: Hängen/Wandmontage",
  backboardtxt3: "Auf Buchstaben schneiden: Hängen/Wandmontage",
  backboardtxt4: "Ständer aus Acryl: Freistehend",
  qty: "Qty",
  editMyDesign: "Meinen Entwurf bearbeiten",
  details: "Ihre Angaben",
};
