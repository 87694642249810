import axios from "axios";
const sendPulseApi = axios.create({
  baseURL: "https://api.sendpulse.com",
});
let count = 0;
export default {
  async auth() {
    await sendPulseApi
      .post("oauth/access_token", {
        grant_type: "client_credentials",
        client_id: process.env.VUE_APP_CLEINT_ID,
        client_secret: process.env.VUE_APP_SECRET_KEY,
      })
      .then((data) => {
        if (data.data.access_token) {
          localStorage.setItem("token", data.data.access_token);
          sendPulseApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.data.access_token}`;
          return true;
        }
      })
      .catch((err) => {
        console.log("SendPulse Authorization err:", err);
        return false;
      });
  },
  async getTemplates() {
    return await sendPulseApi.get("/templates/?owner=me").catch((err) => {
      console.log("Error fetching templates: ", err);
    });
  },
  async sendEmail(data) {
    return sendPulseApi
      .post("/smtp/emails", data)
      .then((el) => {
        console.log("then: ", el);
      })
      .catch((err) => {
        if (count < 2) {
          localStorage.removeItem("token");
          if (this.auth()) {
            this.sendEmail(data);
          } else {
            console.log("SendPulse not authorized");
          }
          count++;
        }
        console.log("Error with send email:", err);
      });
  },
  async checkEmail(email) {
    return await sendPulseApi
      .post("/crm/v1/contacts/get-list-by-email", {
        email: email,
      })
      .catch((err) => {
        console.log("can't fetch users: ", err);
      });
  },
  async createContact(data) {
    data.responsibleId = 7881179;
    return await sendPulseApi.post("/crm/v1/contacts", data).catch((err) => {
      console.log("can't create contact: ", err);
    });
  },
  async getPipelines() {
    return await sendPulseApi.get("/crm/v1/pipelines").catch((err) => {
      console.log("can't fetch pipelines: ", err);
    });
  },
  async getDeals() {
    return await sendPulseApi
      .post("/crm/v1/deals/get-list", {
        limit: 10,
        offset: 0,
        pipelineIds: [22399],
      })
      .catch((err) => {
        console.log("can't fetch pipelines: ", err);
      });
  },
  async createDeal(data) {
    data.pipelineId = 22399;
    data.stepId = 71925;
    data.responsibleId = 7881179;
    return await sendPulseApi.post("/crm/v1/deals", data).catch((err) => {
      console.log("can't create deal: ", err);
    });
  },
};
