<template>
  <div class="options-component options-button">
    <div class="options-component-header">
      <div class="options-component-header-left">{{ size || "-" }}</div>
      <div class="options-component-header-right">
        {{ $t("len") }}: {{ len || "-" }}
      </div>
    </div>
    <div class="options-component-footer">
      <div class="options-component-footer-left">
        <strong>{{
          (prices[valutesShortCode[curVal]] || "-") + " " + valutes[curVal]
        }}</strong>
      </div>
      <div class="options-component-footer-right">
        {{ $t("height") }}: {{ height || "-" }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: () => "",
    },
    len: {
      type: String,
      default: () => "",
    },
    prices: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      curVal: 0,
      valutes: ["$", "Kč", "€"],
      valutesShortCode: ["usd", "czk", "eur"],
    };
  },
  watch: {
    "$i18n.locale"() {
      let curVal = 0;
      switch (this.$i18n.locale) {
        case "eng":
          curVal = 0;
          break;
        case "czk":
          curVal = 1;
          break;
        case "de":
          curVal = 2;
          break;
      }
      this.curVal = curVal;
    },
  },
  async beforeMount() {
    let curVal = 0;
    switch (this.$i18n.locale) {
      case "eng":
        curVal = 0;
        break;
      case "czk":
        curVal = 1;
        break;
      case "de":
        curVal = 2;
        break;
    }
    this.curVal = curVal;
  },
};
</script>
<style lang="scss">
.options-component {
  &-header,
  &-footer {
    display: flex;
    align-items: center;
    height: 30px;
  }
  &-header-right,
  &-footer-right {
    margin-left: auto;
  }
}
</style>
