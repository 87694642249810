import ConstructorPage from "@/components/ConstructorPage.vue";
import CartPage from "@/components/CartPage.vue";
import { createRouter, createWebHistory } from "vue-router";

let routes = [
    {
      name: "ConstructorPage",
      path: "/",
      component: ConstructorPage,
    },
    {
      name: "CartPage",
      path: "/cart",
      component: CartPage,
    },
  ],
  router = createRouter({
    history: createWebHistory("/"),
    routes,
  });
export default router;
