<template>
  <HeaderComponent />
  <div class="constructor-page">
    <div class="constructor-page-title">{{ $t("title") }}</div>
    <div class="constructor-page-subtitle">
      {{ $t("subtitle") }}
    </div>
    <div class="constructor-page-container">
      <div class="constructor-page-sidebar">
        <div class="constructor-page-sidebar-tabs">
          <div
            :class="[
              'constructor-page-sidebar-tabs-item',
              {
                'constructor-page-sidebar-tabs-item-active': tab === 0,
              },
            ]"
            @click="tab = 0"
          >
            {{ $t("text") }}
          </div>
          <div
            :class="[
              'constructor-page-sidebar-tabs-item',
              {
                'constructor-page-sidebar-tabs-item-active': tab === 1,
              },
            ]"
            @click="tab = 1"
          >
            {{ $t("font") }}
          </div>
          <div
            :class="[
              'constructor-page-sidebar-tabs-item',
              {
                'constructor-page-sidebar-tabs-item-active': tab === 2,
              },
            ]"
            @click="tab = 2"
          >
            {{ $t("color") }}
          </div>
        </div>
        <div class="constructor-page-sidebar-tabs-container">
          <div v-if="tab === 0" class="constructor-page-tabs-text">
            <textarea v-model="customText" :placeholder="$t('textarea')" />
          </div>
          <div v-if="tab === 1" class="constructor-page-tabs-fonts">
            <div class="constructor-page-tabs-fonts-title">
              {{ $t("chooseFont") }}
            </div>
            <div class="constructor-page-tabs-fonts-container">
              <div
                v-for="(font, index) in fonts"
                :key="index"
                :class="[
                  'constructor-page-tabs-fonts-item',
                  {
                    'constructor-page-tabs-fonts-item-active':
                      curFont === index,
                  },
                ]"
                :style="`font-family:${font}`"
                @click="curFont = index"
              >
                {{ font }}
              </div>
            </div>
          </div>
          <div v-if="tab === 2" class="constructor-page-tabs-color">
            <div class="constructor-page-tabs-fonts-title">
              {{ $t("chooseColor") }}
            </div>
            <div class="constructor-page-tabs-color-container">
              <div
                v-for="(color, index) in neons"
                :key="index"
                :class="[
                  'constructor-page-tabs-color-icon',
                  {
                    'constructor-page-tabs-color-icon-active':
                      choosenNeon === index,
                  },
                ]"
                @click="choosenNeon = index"
              >
                <i
                  :class="[
                    'far',
                    'fa-lightbulb',
                    `constructor-page-tabs-color-icon-${color}`,
                  ]"
                ></i>
                <div class="constructor-page-tabs-color-icon-text">
                  {{ color }}
                </div>
              </div>
            </div>
          </div>
          <div class="constructor-page-tabs-options">
            <div class="constructor-page-options-title">
              {{ $t("options") }}
            </div>
            <div class="constructor-page-options-container">
              <OptionsComponent
                v-for="(item, index) in optionsList"
                :key="item.id"
                :class="{ 'options-button-active': curOption === index }"
                :size="item.size"
                :prices="{
                  czk: item.priceCZK,
                  eur: item.priceEUR,
                  usd: item.priceUSD,
                }"
                :len="item.len"
                :height="item.height"
                @click="curOption = index"
              />
              <div
                v-if="optionsList[curOption].size == 'Other'"
                class="constructor-page-other-size"
              >
                <div>
                  <div>{{ $t("len") }} (cm):</div>
                  <input
                    v-model="customLen"
                    @change="setCustomLen"
                    type="number"
                  />
                </div>
                <div>
                  <div>{{ $t("height") }} (cm):</div>
                  <input
                    v-model="customHeight"
                    @change="setCustomHeight"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div class="constructor-page-options-subtitle">
              {{ $t("optsubtitle") }}
            </div>
            <div class="constructor-page-options-buttons">
              <div
                @click="indoor = true"
                :class="[
                  'options-button',
                  {
                    'options-button-active': indoor,
                  },
                ]"
              >
                <strong>{{ $t("indoor") }}</strong>
              </div>
              <div
                @click="indoor = false"
                :class="[
                  'options-button',
                  { 'options-button-active': !indoor },
                ]"
              >
                <strong>{{ $t("outdoor") }}</strong>
              </div>
              <a
                class="options-button"
                target="_blank"
                href="https://adsworld.cz/#rec698999062"
              >
                <strong>LOGO</strong>
              </a>
            </div>
            <div class="constructor-page-options-subtitle">
              {{ $t("note") }}
            </div>
          </div>
          <div class="constructor-page-backboard">
            <div class="constructor-page-backboard-title">
              {{ $t("backboard") }}
            </div>
            <div class="constructor-page-backboard-container">
              <div class="constructor-page-backboard-dropdown">
                <div
                  class="constructor-page-backboard-img"
                  @click="openDropdown"
                >
                  <img :src="backBoardImg" alt="reload page" />
                </div>
                <div class="constructor-page-backboard-price">
                  {{ this.$t("free") }}
                </div>
                <div
                  class="constructor-page-backboard-dropdown-icon"
                  @click="openDropdown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z"
                      fill="#212121"
                    />
                  </svg>
                </div>
                <div
                  v-if="isOpen"
                  class="constructor-page-backboard-dropdown-container"
                  v-click-outside="closeDropdown"
                >
                  <div
                    class="constructor-page-backboard-dropdown-item"
                    v-for="(item, index) in backBoardsText"
                    :key="index"
                    @click="setBcb(index)"
                  >
                    {{ item }}
                    <span>{{ this.$t("free") }}</span>
                  </div>
                  <div class="constructor-page-backboard-dropdown-subtitle">
                    Note: 'Cut Around', 'Rectangle' & 'Cut To Letter' come with
                    pre-drilled holes & screws for basic hanging or wall
                    mounting. We also offer optional kits for advanced mounting
                    -see below
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="constructor-page-checkboxes">
            <CheckBox
              :model-value="checkboxes.remoteController"
              @update:modelValue="checkboxes.remoteController = $event"
            >
              <div class="checkbox-label">
                <div class="checkbox-label-text">
                  {{ $t("remoteController") + ":" + $t("free") }}
                </div>
                <div class="checkbox-label-preview">
                  <img
                    src="@/assets/remote-controller.jpeg"
                    alt="reload page"
                  />
                  <div class="checkbox-label-preview-subtitle">
                    The remote and dimmer offers a range of brightness settings
                    and ON & OFF option.
                    <div style="margin-top: 5px">
                      The dimmer and remote allow you to dim the brightness,
                      which can help with exposure when taking photos in front
                      of your new neon sign or having the neon light as a
                      feature for a photo shoot or wedding.
                    </div>
                  </div>
                </div>
              </div>
            </CheckBox>
            <CheckBox
              :model-value="checkboxes.wallMounting"
              @update:modelValue="checkboxes.wallMounting = $event"
            >
              <div class="checkbox-label">
                <div class="checkbox-label-text">
                  {{ $t("wallMounting") + ":" + $t("free") }}
                </div>
                <div class="checkbox-label-preview">
                  <img
                    src="@/assets/wall-mounting-kit.jpeg"
                    alt="reload page"
                    style="width: 300px"
                  />
                  <div class="checkbox-label-preview-subtitle">
                    Make an impact with this professional wall mounting kit.
                    <div style="margin-top: 25px">
                      The kit enables you to mount your LED neon sign with a
                      20mm offset from the wall for an even more stylish look.
                    </div>
                  </div>
                </div>
              </div>
            </CheckBox>
            <CheckBox
              :model-value="checkboxes.signHanging"
              @update:modelValue="checkboxes.signHanging = $event"
            >
              <div class="checkbox-label">
                <div class="checkbox-label-text">
                  {{ $t("signHanging") + ":" + $t("free") }}
                </div>
                <div class="checkbox-label-preview">
                  <img
                    src="@/assets/sign-hanging-kit.jpeg"
                    alt="reload page"
                    style="width: 300px"
                  />
                  <div class="checkbox-label-preview-subtitle">
                    Our hanging wire kit contains everything you need to hang
                    your new LED sign from above. Our signs are lightweight and
                    durable and are designed to hang easily.
                    <div>
                      This hanging wire kit is intended to be mounted to the
                      ceiling so your sign can hang from above, perfect for a
                      storefront window or where wall mounting is not an option.
                    </div>
                  </div>
                </div>
              </div>
            </CheckBox>
          </div>
          <div class="constructor-page-design">
            <div class="constructor-page-design-title">
              {{ $t("design") }}
            </div>
            <CheckBox
              :model-value="checkboxes.design"
              @update:modelValue="checkboxes.design = $event"
              class="constructor-page-design-checkbox"
              :label="$t('designYes')"
            />
            <CheckBox
              :model-value="!checkboxes.design"
              @update:modelValue="checkboxes.design = $event"
              class="constructor-page-design-checkbox"
              :label="$t('designNo')"
            />
          </div>
          <div class="constructor-page-total-price">
            <div class="constructor-page-total-price-text">
              {{ $t("total") }}
            </div>
            <div class="constructor-page-total-price-amount">
              {{ price }}
            </div>
          </div>
          <div class="constructor-page-cart-btn" @click="addToCart">
            {{ $t("addToCart") }}
          </div>
        </div>
      </div>
      <div class="constructor-page-preview">
        <img
          class="constructor-page-preview-img"
          :src="selectedImg"
          alt="reload page"
        />
        <div
          :class="[
            'constructor-page-preview-custom-text',
            `constructor-page-preview-custom-text-${neons[choosenNeon]}`,
          ]"
          :style="`font-family:${fonts[curFont]}`"
        >
          {{ customText || "Your Text" }}
        </div>
        <div class="constructor-page-preview-imgs">
          <img src="@/assets/neon-fon-1.jpg" @click="curBg = 0" />
          <img src="@/assets/bg-bricks.png" @click="curBg = 1" />
          <img src="@/assets/bg-loft.png" @click="curBg = 2" />
          <img src="@/assets/bg-decoration.jpeg" @click="curBg = 3" />
        </div>
        <button
          v-if="!finish"
          class="constructor-page-finish"
          @click="stepPlus(true)"
          :disabled="customText.length === 0"
        >
          {{ $t("addToCart") }}
        </button>
        <div v-else class="constructor-page-finish" @click="editDesign">
          {{ $t("editMyDesign") }}
        </div>
        <div class="constructor-page-preview-subtitle">
          {{ $t("customLedSubtitle") }}
        </div>
        <div v-if="!finish" class="constructor-page-steps">
          <div class="constructor-page-steps-header">
            <div class="constructor-page-steps-header-title">
              {{ steps[currentStep] }}
            </div>
            <div class="constructor-page-steps-header-step">
              {{ currentStep + 1 }} / {{ steps.length }}
            </div>
          </div>
          <div class="constructor-page-steps-content">
            <div
              v-if="currentStep === 0"
              class="constructor-page-steps-textarea"
            >
              <textarea v-model="customText" :placeholder="$t('textarea')" />
            </div>
            <div v-if="currentStep === 1" class="constructor-page-steps-fonts">
              <div
                v-for="(font, index) in fonts"
                :key="index"
                :class="[
                  'constructor-page-tabs-fonts-item',
                  {
                    'constructor-page-tabs-fonts-item-active':
                      curFont === index,
                  },
                ]"
                :style="`font-family:${font}`"
                @click="curFont = index"
              >
                {{ font }}
              </div>
            </div>
            <div v-if="currentStep === 2" class="constructor-page-steps-colors">
              <div class="constructor-page-tabs-color-container">
                <div
                  v-for="(color, index) in neons"
                  :key="index"
                  :class="[
                    'constructor-page-tabs-color-icon',
                    {
                      'constructor-page-tabs-color-icon-active':
                        choosenNeon === index,
                    },
                  ]"
                  @click="choosenNeon = index"
                >
                  <i
                    :class="[
                      'far',
                      'fa-lightbulb',
                      `constructor-page-tabs-color-icon-${color}`,
                    ]"
                  ></i>
                  <div class="constructor-page-tabs-color-icon-text">
                    {{ color }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentStep === 3" class="constructor-page-steps-size">
              <div class="constructor-page-options-container">
                <OptionsComponent
                  v-for="(item, index) in optionsList"
                  :key="item.id"
                  :class="{ 'options-button-active': curOption === index }"
                  :size="item.size"
                  :prices="{
                    czk: item.priceCZK,
                    eur: item.priceEUR,
                    usd: item.priceUSD,
                  }"
                  :len="item.len"
                  :height="item.height"
                  @click="curOption = index"
                />
              </div>
            </div>
            <div
              v-if="currentStep === 4"
              class="constructor-page-steps-backboard"
            >
              <div class="constructor-page-steps-backboard-container">
                <div
                  v-for="(item, index) in backBoardsText"
                  :key="index"
                  :class="[
                    'constructor-page-backboard-dropdown-item',
                    {
                      'constructor-page-backboard-dropdown-item-active':
                        curBcb === index,
                    },
                  ]"
                  @click="setBcb(index)"
                >
                  {{ item }}
                  <span>{{ this.$t("free") }}</span>
                </div>
              </div>
              <div class="constructor-page-steps-backboard-img">
                <img :src="backBoardImg" alt="reload page" />
              </div>
            </div>
            <div
              v-if="currentStep === 5"
              class="constructor-page-steps-mounting"
            >
              <CheckBox
                :model-value="checkboxes.remoteController"
                @update:modelValue="checkboxes.remoteController = $event"
              >
                <div class="checkbox-label">
                  <div class="checkbox-label-text">
                    {{ $t("remoteController") + ":" + $t("free") }}
                  </div>
                  <div class="checkbox-label-preview">
                    <img
                      style="width: 50%; height: 540px"
                      src="@/assets/remote-controller.jpeg"
                      alt="reload page"
                    />
                    <div class="checkbox-label-preview-subtitle">
                      The remote and dimmer offers a range of brightness
                      settings and ON & OFF option.
                      <div style="margin-top: 5px">
                        The dimmer and remote allow you to dim the brightness,
                        which can help with exposure when taking photos in front
                        of your new neon sign or having the neon light as a
                        feature for a photo shoot or wedding.
                      </div>
                    </div>
                  </div>
                </div>
              </CheckBox>
              <CheckBox
                :model-value="checkboxes.wallMounting"
                @update:modelValue="checkboxes.wallMounting = $event"
              >
                <div class="checkbox-label">
                  <div class="checkbox-label-text">
                    {{ $t("wallMounting") + ":" + $t("free") }}
                  </div>
                  <div class="checkbox-label-preview">
                    <img
                      src="@/assets/wall-mounting-kit.jpeg"
                      alt="reload page"
                      style="width: 100%; height: 500px"
                    />
                    <div
                      class="checkbox-label-preview-subtitle"
                      style="margin-top: 50px"
                    >
                      Make an impact with this professional wall mounting kit.
                      <div style="margin-top: 25px">
                        The kit enables you to mount your LED neon sign with a
                        20mm offset from the wall for an even more stylish look.
                      </div>
                    </div>
                  </div>
                </div>
              </CheckBox>
              <CheckBox
                :model-value="checkboxes.signHanging"
                @update:modelValue="checkboxes.signHanging = $event"
              >
                <div class="checkbox-label">
                  <div class="checkbox-label-text">
                    {{ $t("signHanging") + ":" + $t("free") }}
                  </div>
                  <div class="checkbox-label-preview">
                    <img
                      src="@/assets/sign-hanging-kit.jpeg"
                      alt="reload page"
                      style="width: 100%; height: 510px"
                    />
                    <div class="checkbox-label-preview-subtitle">
                      Our hanging wire kit contains everything you need to hang
                      your new LED sign from above. Our signs are lightweight
                      and durable and are designed to hang easily.
                      <div>
                        This hanging wire kit is intended to be mounted to the
                        ceiling so your sign can hang from above, perfect for a
                        storefront window or where wall mounting is not an
                        option.
                      </div>
                    </div>
                  </div>
                </div>
              </CheckBox>
            </div>
            <div v-if="currentStep === 6" class="constructor-page-steps-extras">
              <div class="constructor-page-options-buttons">
                <div
                  @click="indoor = true"
                  :class="[
                    'options-button',
                    { 'options-button-active': indoor },
                  ]"
                >
                  <strong>{{ $t("indoor") }}</strong>
                </div>
                <div
                  @click="indoor = false"
                  :class="[
                    'options-button',
                    { 'options-button-active': !indoor },
                  ]"
                >
                  <strong>{{ $t("outdoor") }}</strong>
                </div>
                <a
                  class="options-button"
                  target="_blank"
                  href="https://adsworld.cz/#rec698999062"
                >
                  <strong>LOGO</strong>
                </a>
              </div>
            </div>
          </div>
          <div class="constructor-page-steps-actions">
            <div class="constructor-page-steps-actions-left" @click="stepMinus">
              <i class="fa fa-arrow-left" />
            </div>
            <div
              :class="[
                'constructor-page-steps-actions-right',
                {
                  'constructor-page-steps-actions-right-finish':
                    currentStep + 1 === steps.length,
                },
              ]"
              @click="stepPlus()"
            >
              <div v-if="currentStep + 1 === steps.length">Finish</div>
              <i v-else class="fa fa-arrow-right" />
            </div>
          </div>
        </div>
        <div v-else class="constructor-page-info-finish">
          <div class="constructor-page-info-finish-actions">
            <div class="constructor-page-info-finish-price">
              <div class="constructor-page-info-finish-price-text">
                {{ $t("price") }}:
              </div>
              <div class="constructor-page-info-finish-price-value">
                {{ price }}
              </div>
            </div>
            <div
              class="constructor-page-info-finish-to-cart"
              @click="addToCart"
            >
              {{ $t("addToCart") }}
            </div>
          </div>
          <div class="constructor-page-info-finish-details">
            <div class="constructor-page-info-finish-details-header">
              {{ $t("details") }}
            </div>
            <div class="constructor-page-info-finish-details-content">
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("size") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{ optionsList[curOption].size }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("text") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{ customText }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("color") }}
                </div>
                <div
                  class="constructor-page-info-finish-details-item-value"
                  style="text-transform: capitalize"
                >
                  {{ neons[choosenNeon] }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("font") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{ fonts[curFont] }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("board") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{ backBoardsText[curBcb] }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("wallMounting") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{ checkboxes["wallMounting"] ? "Yes" : "No" }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("signHanging") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{ checkboxes["signHanging"] ? "Yes" : "No" }}
                </div>
              </div>
              <div class="constructor-page-info-finish-details-item">
                <div class="constructor-page-info-finish-details-item-title">
                  {{ $t("designRequested") }}
                </div>
                <div class="constructor-page-info-finish-details-item-value">
                  {{
                    checkboxes["Design proof requested:"] ? $t("yes") : $t("no")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="faq-img">
          <img :src="langImg" />
        </div>
      </div>
    </div>
  </div>
  <FAQ class="constructor-page-faq" />
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import OptionsComponent from "@/components/OptionsComponent.vue";
import vClickOutside from "click-outside-vue3";
import CheckBox from "@/components/CheckBox.vue";
import FAQ from "@/components/FAQ.vue";
import axios from "axios";

export default {
  name: "ConstructorPage",
  components: { FAQ, CheckBox, OptionsComponent, HeaderComponent },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      tab: 0,
      backgrounds: [
        "neon-fon-1.jpg",
        "bg-bricks.png",
        "bg-loft.png",
        "bg-decoration.jpeg",
      ],
      curBg: 0,
      customText: "",
      optionsList: [],
      curOption: 1,
      backBoards: ["1.png", "2.png", "3.png", "4.png"],
      backBoardsText: [
        this.$t("backboardtxt1"),
        this.$t("backboardtxt2"),
        this.$t("backboardtxt3"),
        this.$t("backboardtxt4"),
      ],
      curBcb: 0,
      isOpen: false,
      indoor: true,
      checkboxes: {
        remoteController: false,
        wallMounting: false,
        signHanging: false,
        design: false,
      },
      fonts: [
        "Alexa",
        "Bayview",
        "Amsterdam",
        "Greenworld",
        "NewCursive",
        "Barcelona",
        "Vintage",
        "Amanda",
        "Freespirit",
        "Chelsea",
        "Signature",
        "Austin",
        "Neonscript",
        "Freehand",
        "LoveNote",
        "Northshore",
        "Beachfront",
        "Royalty",
        "Rocket",
        "WildScript",
        "Avante",
        "Monaco",
        "ClassicType",
        "Typewriter",
        "Buttercup",
        "Melbourne",
        "NeoTokyo",
        "Loveneon",
        "Marquee",
        "Mayfair",
        "NeonGlow",
        "NeonLite",
        "Neontrace",
        "Nevada",
        "SciFi",
      ],
      curFont: 0,
      neons: [
        "warm-white",
        "white",
        "lemon-yellow",
        "golden-yellow",
        "orange",
        "red",
        "deep-pink",
        "deep-blue",
        "tropical-blue",
        "deep-green",
        "cold-white",
      ],
      langImgs: {
        eng: "LIGHTUP WORLDS_ENG_W.png",
        de: "LIGHTUP WORLDS_GER_W.png",
        czk: "LIGHTUP WORLDS_CS_W.png",
        ru: "LIGHTUP WORLDS_RU_W.png",
      },
      choosenNeon: 0,
      steps: [
        this.$t("text"),
        this.$t("chooseFont"),
        this.$t("chooseColor"),
        this.$t("size"),
        this.$t("backboard"),
        this.$t("mounting"),
        this.$t("extras"),
      ],
      currentStep: 0,
      finish: false,
      outdoorPercent: 0.15,
      customLen: 0,
      customHeight: 0,
    };
  },
  computed: {
    selectedImg() {
      const img = this.backgrounds[this.curBg];
      return require(`@/assets/${img}`);
    },
    backBoardImg() {
      const img = this.backBoards[this.curBcb];
      return require(`@/assets/${img}`);
    },
    langImg() {
      const img = this.langImgs[this.$i18n.locale] || this.langImgs["eng"];
      return require(`@/assets/${img}`);
    },
    price() {
      if (this.indoor) {
        return this.$i18n.locale === "czk"
          ? this.optionsList[this.curOption].priceCZK + " Kč"
          : this.$i18n.locale === "de"
          ? this.optionsList[this.curOption].priceEUR + " €"
          : this.optionsList[this.curOption].priceUSD + " $";
      } else if (this.customLen > 0 && this.customHeight > 0) {
        return "-";
      } else {
        return this.$i18n.locale === "czk"
          ? Number(this.optionsList[this.curOption].priceCZK) +
              Number(
                this.optionsList[this.curOption].priceCZK * this.outdoorPercent
              ) +
              " Kč"
          : this.$i18n.locale === "de"
          ? Number(this.optionsList[this.curOption].priceEUR) +
            Number(
              this.optionsList[this.curOption].priceEUR * this.outdoorPercent
            ) +
            " €"
          : Number(this.optionsList[this.curOption].priceUSD) +
            Number(
              this.optionsList[this.curOption].priceUSD * this.outdoorPercent
            ) +
            " $";
      }
    },
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    setBcb(value) {
      this.curBcb = value;
      this.closeDropdown();
    },
    openDropdown() {
      this.isOpen = !this.isOpen;
    },
    addToCart() {
      let cart = localStorage.getItem("cart");
      const item = {
        text: this.customText,
        options: this.optionsList[this.curOption],
        customSize: {
          height: this.customHeight,
          length: this.customLen,
        },
        usage: this.indoor ? "Indoor" : "Outdoor",
        board: this.backBoardsText[this.curBcb],
        boardIndex: this.curBcb,
        advanced: this.checkboxes,
        font: this.fonts[this.curFont],
        color: this.neons[this.choosenNeon],
        priceUSD: this.indoor
          ? this.optionsList[this.curOption].priceUSD
          : Number(this.optionsList[this.curOption].priceUSD) +
            Number(
              this.optionsList[this.curOption].priceUSD * this.outdoorPercent
            ),
        priceCZK: this.indoor
          ? this.optionsList[this.curOption].priceCZK
          : Number(this.optionsList[this.curOption].priceCZK) +
            Number(
              this.optionsList[this.curOption].priceCZK * this.outdoorPercent
            ),
        priceEUR: this.indoor
          ? this.optionsList[this.curOption].priceEUR
          : Number(this.optionsList[this.curOption].priceEUR) +
            Number(
              this.optionsList[this.curOption].priceEUR * this.outdoorPercent
            ),
        count: 1,
      };
      if (this.curOption !== 6) {
        delete item.customSize;
      }
      if (!cart) {
        localStorage.setItem("cart", JSON.stringify([item]));
        this.$router.push("/cart");
        return;
      }
      cart = JSON.parse(cart);
      cart.push(item);
      localStorage.setItem("cart", JSON.stringify(cart));
      this.$router.push("/cart");
    },
    stepPlus(lastStep) {
      if (lastStep) {
        this.finish = true;
      } else if (this.currentStep + 1 === this.steps.length) {
        this.finish = true;
      } else {
        this.currentStep++;
      }
    },
    stepMinus() {
      if (this.currentStep === 0) return;
      this.currentStep--;
    },
    editDesign() {
      this.finish = false;
      this.currentStep = 0;
    },
    setCustomLen(e) {
      const value = e.target.value;
      if (value < 0) {
        this.customLen = 0;
        return;
      }
      if (value > 350) {
        this.customLen = 350;
        return;
      }
      this.customLen = value;
    },
    setCustomHeight(e) {
      const value = e.target.value;
      if (value < 0) {
        this.customHeight = 0;
        return;
      }
      if (value > 230) {
        this.customHeight = 230;
        return;
      }
      this.customHeight = value;
    },
  },
  mounted() {
    const item = this.$store.getters.getEditableItem;
    if (Object.keys(item).length !== 0) {
      this.customText = item.text;
      this.indoor = item.usage === "Indoor" ? true : false;
      this.curFont = this.fonts.findIndex((el) => el === item.font);
      this.curOption = item.options.id - 1;
      this.checkboxes.signHanging = item.advanced.signHanging;
      this.checkboxes.wallMounting = item.advanced.wallMounting;
      this.checkboxes.design = item.advanced.design;
      this.checkboxes.remoteController = item.advanced.remoteController;
      this.choosenNeon = this.neons.findIndex((el) => el === item.color);
      this.curBcb = item.boardIndex;
      this.customLen = item.customSize.length;
      this.customHeight = item.customSize.height;
    }
  },
  async beforeMount() {
    await axios.get("/fetchCost.php").then((data) => {
      if (data.data.status) {
        this.optionsList = data.data.data;
      } else {
        console.log("Error: ", data.data.data);
      }
    });
  },
};
</script>
<style lang="scss">
.constructor-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 200px;
  &-title {
    text-align: center;
    font-size: 60px;
    margin-top: 32px;
  }
  &-subtitle {
    text-align: center;
    font-size: 20px;
    letter-spacing: 10px;
    color: grey;
    margin-top: 20px;
  }
  &-container {
    margin-top: 50px;
    display: flex;
    gap: 15px;
  }
  &-sidebar {
    border: 1px solid #fa7126;
    max-width: 438px;
    box-shadow: 0px 6px 23px -2px rgba(0, 0, 0, 0.27);
    &-tabs {
      display: flex;
      &-item {
        //padding: 10px 50px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        border-right: 1px solid #fa7126;
        border-bottom: 1px solid #fa7126;
        color: #fa7126;
        &:hover {
          cursor: pointer;
          color: #fff;
          background: #fa7126;
        }
        &-active {
          background: #fa7126;
          color: #fff;
        }
      }
      &-container {
        margin-top: 10px;
        padding: 10px;
      }
    }
  }
  &-preview {
    border-radius: 10px;
    //height: 800px;
    position: relative;
    &-custom-text {
      font-size: 80px;
      white-space: break-spaces;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 70px;
      color: #fff;
      font-weight: 500;
      font-family: "Alexa", sans-serif;
      &-warm-white {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(255, 253, 207) 0px 0px 20px,
          rgb(255, 253, 207) 0px 0px 30px, rgb(255, 253, 207) 0px 0px 40px,
          rgb(255, 253, 207) 0px 0px 55px, rgb(255, 253, 207) 0px 0px 75px;
      }
      &-white {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(225, 227, 230) 0px 0px 20px,
          rgb(225, 227, 230) 0px 0px 30px, rgb(225, 227, 230) 0px 0px 40px,
          rgb(255, 253, 207) 0px 0px 55px, rgb(255, 253, 207) 0px 0px 75px;
      }
      &-lemon-yellow {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(255, 249, 124) 0px 0px 20px,
          rgb(255, 249, 124) 0px 0px 30px, rgb(255, 249, 124) 0px 0px 40px,
          rgb(255, 249, 124) 0px 0px 55px, rgb(255, 249, 124) 0px 0px 75px;
      }
      &-golden-yellow {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(255, 214, 46) 0px 0px 20px,
          rgb(255, 214, 46) 0px 0px 30px, rgb(255, 214, 46) 0px 0px 40px,
          rgb(255, 214, 46) 0px 0px 55px, rgb(255, 214, 46) 0px 0px 75px;
      }
      &-orange {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(255, 141, 2) 0px 0px 20px,
          rgb(255, 141, 2) 0px 0px 30px, rgb(255, 141, 2) 0px 0px 40px,
          rgb(255, 141, 2) 0px 0px 55px, rgb(255, 141, 2) 0px 0px 75px;
      }
      &-red {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(255, 42, 77) 0px 0px 20px,
          rgb(255, 42, 77) 0px 0px 30px, rgb(255, 42, 77) 0px 0px 40px,
          rgb(255, 42, 77) 0px 0px 55px, rgb(255, 42, 77) 0px 0px 75px;
      }
      &-deep-pink {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(255, 92, 232) 0px 0px 20px,
          rgb(255, 92, 232) 0px 0px 30px, rgb(255, 92, 232) 0px 0px 40px,
          rgb(255, 92, 232) 0px 0px 55px, rgb(255, 92, 232) 0px 0px 75px;
      }
      &-deep-blue {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(0, 6, 241) 0px 0px 20px,
          rgb(0, 6, 241) 0px 0px 30px, rgb(0, 6, 241) 0px 0px 40px,
          rgb(0, 6, 241) 0px 0px 55px, rgb(0, 6, 241) 0px 0px 75px;
      }
      &-tropical-blue {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(36, 183, 222) 0px 0px 20px,
          rgb(36, 183, 222) 0px 0px 30px, rgb(36, 183, 222) 0px 0px 40px,
          rgb(36, 183, 222) 0px 0px 55px, rgb(36, 183, 222) 0px 0px 75px;
      }
      &-deep-green {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(57, 255, 82) 0px 0px 20px,
          rgb(57, 255, 82) 0px 0px 30px, rgb(57, 255, 82) 0px 0px 40px,
          rgb(57, 255, 82) 0px 0px 55px, rgb(57, 255, 82) 0px 0px 75px;
      }
      &-cold-white {
        text-shadow: rgb(255, 255, 255) 0px 0px 5px,
          rgb(255, 255, 255) 0px 0px 10px, rgb(168, 180, 206) 0px 0px 20px,
          rgb(168, 180, 206) 0px 0px 30px, rgb(168, 180, 206) 0px 0px 40px,
          rgb(168, 180, 206) 0px 0px 55px, rgb(168, 180, 206) 0px 0px 75px;
      }
    }
    &-img {
      width: 716px;
      height: 744px;
    }
    &-imgs {
      position: absolute;
      display: flex;
      align-items: center;
      left: 50%;
      transform: translateX(-50%);
      top: 630px;
      gap: 10px;
      img {
        border-radius: 10px;
        cursor: pointer;
        width: 70px;
        height: 70px;
      }
    }
    &-subtitle {
      text-align: center;
      color: #333;
      margin-top: 10px;
    }
  }
  &-tabs {
    &-text {
      textarea {
        box-sizing: border-box;
        resize: none;
        color: #555;
        font-size: 1.1em;
        width: 420px;
        height: 100px;
        outline: none;
        border: 1px solid #f3f3f3;
        text-align: center;
        background: #f5f5f5;
        border-radius: 10px;
        padding: 10px;
        overflow-y: hidden;
      }
    }
    &-fonts {
      &-title {
        text-align: center;
      }
      &-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
        padding-left: 10px;
        padding-bottom: 10px;
      }
      &-item {
        cursor: pointer;
        font-size: 28px;
        &-active {
          text-shadow: rgb(255, 255, 255) 0px 0px 2px,
            rgb(255, 255, 255) 0px 0px 4px, #fa7126 0px 0px 8px,
            #fa7126 0px 0px 6px, #a2e6ff 0px 0px 8px, #fa7126 0px 0px 22px,
            #fa7126 0px 0px 30px;
        }
      }
    }
    &-color {
      &-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-left: 10px;
        margin-top: 20px;
      }
      &-icon {
        font-size: 30px;
        width: 40px;
        text-align: center;
        cursor: pointer;
        &-text {
          font-size: 12px;
          color: #2b2b2b;
          text-align: center;
          margin-top: 5px;
          text-transform: capitalize;
        }
        &-warm-white {
          color: rgb(255, 253, 207);
        }
        &-white {
          color: rgb(239, 228, 176);
        }
        &-lemon-yellow {
          color: rgb(255, 249, 124);
        }
        &-golden-yellow {
          color: rgb(255, 214, 46);
        }
        &-orange {
          color: rgb(255, 141, 2);
        }
        &-red {
          color: rgb(255, 42, 77);
        }
        &-deep-pink {
          color: rgb(255, 92, 232);
        }
        &-deep-blue {
          color: rgb(2, 116, 252);
        }
        &-tropical-blue {
          color: rgb(36, 183, 222);
        }
        &-deep-green {
          color: rgb(11, 215, 72);
        }
        &-cold-white {
          color: rgb(225, 227, 250);
        }
      }
    }
  }
  &-options {
    &-title {
      font-size: 15pt;
      font-weight: 700;
      margin-top: 16px;
    }
    &-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;
      .options-component {
        width: 190px;
      }
    }
    &-subtitle {
      font-size: 9pt;
      margin-top: 20px;
    }
    &-buttons {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      .options-button {
        width: 190px;
        text-align: center;
        font-size: 14.4px;
        font-weight: 500;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #000;
      }
    }
  }
  &-backboard {
    &-title {
      font-size: 20px;
      font-weight: 500;
      margin-top: 20px;
      text-align: center;
    }
    &-container {
      width: 388px;
      height: 184px;
      background: #f5f5f5;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    &-dropdown {
      width: 194px;
      height: 163px;
      position: relative;
      &-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 30%;
        right: -15px;
        cursor: pointer;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 5px #fa7126, 0px 0px 10px #fa7126;
      }
      &-container {
        position: absolute;
        padding: 10px;
        background: #f5f5f5;
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        bottom: -280px;
        left: -100px;
        width: 388px;
        z-index: 1;
        height: 238px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      }
      &-item {
        color: #333;
        font-size: 14px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        span {
          margin-left: auto;
          padding-right: 15px;
        }
      }
      &-subtitle {
        font-size: 9pt;
        margin-top: 50px;
      }
    }
    &-img {
      width: 184px;
      height: 122px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-price {
      text-align: center;
      font-size: 15px;
    }
  }
  &-design {
    margin-top: 30px;
    &-title {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
    }
    &-checkbox {
      margin-top: 30px;
      font-size: 18px;
    }
  }
  &-total-price {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border-radius: 15px;
    color: #2b2b2b;
    padding: 10px 15px;
    margin-top: 20px;
    &-text {
      font-size: 18px;
    }
    &-amount {
      margin-left: auto;
      margin-right: 10px;
      font-size: 30px;
    }
  }
  &-cart-btn {
    font-size: 21px;
    letter-spacing: 7px;
    background: #fa7126;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 15px;
    &:hover {
      opacity: 0.75;
    }
  }
  .checkbox-label {
    position: relative;
    margin-top: 30px;
    &-text {
      padding-left: 10px;
    }
    &:hover {
      .checkbox-label-preview {
        display: block;
      }
    }
    &-preview {
      position: absolute;
      width: 300px;
      height: 300px;
      border-radius: 15px;
      background: #f3f3f3;
      font-size: 14px;
      left: 300px;
      z-index: 1;
      bottom: 10px;
      display: none;
      color: #333;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      img {
        margin: 15px auto;
        display: block;
        width: 150px;
        height: 150px;
        border-radius: 15px;
      }
    }
  }
  .constructor-page-tabs-color-icon-active {
    .constructor-page-tabs-color-icon-warm-white {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(255, 253, 207) 0px 0px 20px,
        rgb(255, 253, 207) 0px 0px 30px, rgb(255, 253, 207) 0px 0px 40px,
        rgb(255, 253, 207) 0px 0px 55px, rgb(255, 253, 207) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-white {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(225, 227, 230) 0px 0px 20px,
        rgb(225, 227, 230) 0px 0px 30px, rgb(225, 227, 230) 0px 0px 40px,
        rgb(255, 253, 207) 0px 0px 55px, rgb(255, 253, 207) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-lemon-yellow {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(255, 249, 124) 0px 0px 20px,
        rgb(255, 249, 124) 0px 0px 30px, rgb(255, 249, 124) 0px 0px 40px,
        rgb(255, 249, 124) 0px 0px 55px, rgb(255, 249, 124) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-golden-yellow {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(255, 214, 46) 0px 0px 20px,
        rgb(255, 214, 46) 0px 0px 30px, rgb(255, 214, 46) 0px 0px 40px,
        rgb(255, 214, 46) 0px 0px 55px, rgb(255, 214, 46) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-orange {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(255, 141, 2) 0px 0px 20px,
        rgb(255, 141, 2) 0px 0px 30px, rgb(255, 141, 2) 0px 0px 40px,
        rgb(255, 141, 2) 0px 0px 55px, rgb(255, 141, 2) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-red {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(255, 42, 77) 0px 0px 20px,
        rgb(255, 42, 77) 0px 0px 30px, rgb(255, 42, 77) 0px 0px 40px,
        rgb(255, 42, 77) 0px 0px 55px, rgb(255, 42, 77) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-deep-pink {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(255, 92, 232) 0px 0px 20px,
        rgb(255, 92, 232) 0px 0px 30px, rgb(255, 92, 232) 0px 0px 40px,
        rgb(255, 92, 232) 0px 0px 55px, rgb(255, 92, 232) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-deep-blue {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(0, 6, 241) 0px 0px 20px,
        rgb(0, 6, 241) 0px 0px 30px, rgb(0, 6, 241) 0px 0px 40px,
        rgb(0, 6, 241) 0px 0px 55px, rgb(0, 6, 241) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-tropical-blue {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(36, 183, 222) 0px 0px 20px,
        rgb(36, 183, 222) 0px 0px 30px, rgb(36, 183, 222) 0px 0px 40px,
        rgb(36, 183, 222) 0px 0px 55px, rgb(36, 183, 222) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-deep-green {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(57, 255, 82) 0px 0px 20px,
        rgb(57, 255, 82) 0px 0px 30px, rgb(57, 255, 82) 0px 0px 40px,
        rgb(57, 255, 82) 0px 0px 55px, rgb(57, 255, 82) 0px 0px 75px;
    }
    .constructor-page-tabs-color-icon-cold-white {
      color: #fff;
      text-shadow: rgb(255, 255, 255) 0px 0px 5px,
        rgb(255, 255, 255) 0px 0px 10px, rgb(168, 180, 206) 0px 0px 20px,
        rgb(168, 180, 206) 0px 0px 30px, rgb(168, 180, 206) 0px 0px 40px,
        rgb(168, 180, 206) 0px 0px 55px, rgb(168, 180, 206) 0px 0px 75px;
    }
  }
  &-faq {
    &-items-mobile {
      display: none;
    }
  }
  &-steps {
    display: none;
    margin-top: 10px;
    border-bottom: 3px solid #000;
    &-header {
      font-size: 70px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 100px;
      border-bottom: 3px solid #000;
      &-step {
        margin-left: auto;
      }
    }
    &-textarea {
      width: 100%;
      font-size: 100px;
      margin-top: 40px;
      textarea {
        width: 100%;
        height: 500px;
        border: none;
        text-align: center;
        background: rgba(7, 7, 7, 0.1);
        border-radius: 15px;
      }
    }
    &-fonts {
      display: flex;
      flex-wrap: wrap;
      gap: 80px;
      margin-top: 60px;
    }
    &-colors {
      margin-top: 100px;
      font-size: 120px;
    }
    .constructor-page-tabs-color-container {
      gap: 110px;
    }
    &-actions {
      font-size: 80px;
      display: flex;
      align-items: center;
      &-left,
      &-right {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-left {
        margin-left: auto;
      }
      &-right {
        background: #fa7126;
        color: #fff;
        &-finish {
          width: 300px;
        }
      }
    }
    &-size {
      margin-top: 30px;
    }
    &-backboard {
      padding-bottom: 30px;
      margin-top: 30px;
      &-container {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
      }
      &-img {
        width: 100%;
        margin-top: 30px;
        img {
          width: 100%;
        }
      }
      .constructor-page-backboard-dropdown-item {
        box-sizing: border-box;
        border-radius: 20px;
        display: block;
        text-align: center;
        width: 570px;
        height: 200px;
        border: 3px solid #000;
        background: rgba(204, 204, 204, 0.3);
        font-size: 40px;
        &-active {
          border-color: #fa7126;
          background: #fff;
        }
        span {
          display: block;
          font-size: 40px;
        }
      }
    }
    &-mounting {
      padding-bottom: 30px;
      .container {
        font-size: 70px;
        .checkmark {
          top: 10px;
          width: 65px;
          height: 65px;
          &::after {
            left: 18px;
            top: 7px;
            width: 15px;
            height: 30px;
            border-width: 0 10px 10px 0;
          }
        }
        .checkbox-label-text {
          padding-left: 70px;
        }
      }
      .checkbox-label-preview {
        width: 100%;
        height: 830px;
        left: -30px;
        bottom: -539px;
        font-size: 40px;
      }
    }
    &-extras {
      padding-bottom: 30px;
    }
    &-content {
      width: 100%;
    }
  }
  &-finish {
    top: 1290px;
    left: 50%;
    font-family: Typewriter, sans-serif;
    transform: translateX(-50%);
    position: absolute;
    width: 500px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: 150px;
    background: #fa7126;
    color: #fff;
    font-size: 60px;
    &:disabled {
      opacity: 0.75;
    }
  }
  &-info-finish {
    display: none;
    &-price {
      display: flex;
      align-items: center;
      font-size: 50px;
      justify-content: center;
      &-text {
        font-size: 30px;
      }
      &-value {
        margin-left: 60px;
        color: red;
        font-weight: 500;
      }
    }
    &-to-cart {
      margin: 20px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 100px;
      font-size: 40px;
      border: 3px solid #fa7126;
      border-radius: 20px;
      font-family: "Avenir Next", sans-serif;
    }
    &-details {
      &-header {
        font-size: 60px;
        font-weight: 500;
        margin-top: 30px;
        color: #3e3e3e;
        text-transform: uppercase;
        font-family: Buttercup, sans-serif;
        border-bottom: 2px solid #cccccc;
        padding-bottom: 30px;
      }
      &-content {
        margin-top: 30px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
      }
      &-item {
        font-size: 50px;
        color: #3e3e3e;
        display: flex;
        align-items: center;
        gap: 30px;
        max-width: 100%;
        min-width: 40%;
        &-title {
          font-weight: 600;
        }
      }
    }
    &-actions {
      border-top: 2px solid #ccc;
      border-bottom: 2px solid #ccc;
      padding-bottom: 30px;
      padding-top: 30px;
      margin-top: 20px;
    }
  }
  &-other-size {
    margin-top: -10px;
  }
}
@media (min-device-width: 320px) and (max-device-width: 1023px) {
  .constructor-page {
    &-title {
      font-size: 80px;
    }
    &-subtitle {
      font-size: 40px;
    }
    &-container {
      flex-wrap: wrap-reverse;
      .options-component {
        width: 590px;
        box-sizing: border-box;
        height: 300px;
        font-size: 50px;
        padding: 30px;
        &-footer {
          margin-top: 120px;
          height: 50px;
        }
      }
    }
    &-info-finish {
      display: block;
    }
    &-preview {
      width: 100%;
      &-img {
        width: 100%;
        height: 1500px;
      }
      &-imgs {
        top: 1050px;
      }
      &-imgs img {
        width: 170px;
        height: 170px;
      }
      &-subtitle {
        font-size: 40px;
      }
    }
    &-sidebar {
      display: none;
      &-mobile {
        display: block;
      }
    }
    &-preview-custom-text {
      font-size: 158px;
    }
    &-sidebar-tabs-item {
      width: 400px;
      height: 100px;
      font-size: 40px;
    }
    &-tabs-text textarea {
      width: 100%;
      height: 400px;
      font-size: 50px;
    }
    &-options {
      &-title {
        font-size: 50px;
        text-align: center;
        margin-top: 80px;
      }
      &-subtitle {
        font-size: 40px;
        text-align: center;
      }
      &-buttons .options-button {
        width: 50%;
        height: 250px;
        font-size: 60px;
      }
    }
    &-tabs-fonts-item {
      font-size: 70px;
    }
    &-tabs-color-icon {
      font-size: 150px;
      width: 150px;
    }
    &-tabs-color-icon-text {
      font-size: 50px;
    }
    &-tabs-fonts-title {
      font-size: 50px;
    }
    &-backboard {
      &-title {
        font-size: 40px;
        margin-top: 30px;
      }
      &-container {
        width: 100%;
        height: 450px;
      }
      &-dropdown {
        width: 50%;
        height: 400px;
        &-container {
          width: 150%;
          height: 110%;
        }
        &-subtitle {
          font-size: 30px;
        }
        &-item {
          font-size: 40px;
        }
        &-icon {
          top: 40%;
        }
      }
      &-img {
        width: 95%;
        height: 380px;
        img {
          width: 100%;
        }
      }
      &-price {
        font-size: 40px;
      }
    }
    &-checkboxes {
      .checkbox-label {
        font-size: 50px;
        margin-left: 50px;
      }
      .checkmark {
        width: 60px;
        height: 60px;
        &::after {
          width: 15px;
          height: 30px;
          left: 20px;
          top: 8px;
          border-width: 0 7px 7px 0;
        }
      }
    }
    &-design {
      &-title {
        font-size: 35px;
      }
      &-checkbox {
        .checkmark {
          width: 60px;
          height: 60px;
          margin-left: -50px;
          &::after {
            width: 15px;
            height: 30px;
            left: 20px;
            top: 8px;
            border-width: 0 7px 7px 0;
          }
        }
      }
      label {
        font-size: 50px;
        margin-left: 50px;
      }
    }
    &-total-price {
      height: 100px;
      &-text {
        font-size: 50px;
      }
      &-amount {
        font-size: 50px;
      }
    }
    &-cart-btn {
      height: 100px;
      font-size: 40px;
    }
    &-faq {
      &-items-desc {
        display: none !important;
      }
      &-items-mobilde {
        display: flex !important;
      }
    }
    &-steps {
      display: block;
    }
    &-finish {
      display: flex;
    }
  }
  .faq-title {
    font-size: 80px;
  }
  .faq-items {
    display: block;
  }
  .faq-item {
    width: 100%;
    margin-top: 30px;
    img {
      width: 20%;
    }
    &-title {
      font-size: 50px;
    }
    &-desc {
      font-size: 40px;
    }
  }
  .faq-img {
    width: 100%;
    height: 570px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .faq-questions {
    display: block;
    &-left {
      max-width: 100%;
      text-align: center;
      font-size: 70px;
    }
    &-right {
      width: 100%;
    }
    .faq-dropdown {
      margin-top: 50px;
      &-title {
        font-size: 40px;
      }
      &-desc {
        font-size: 30px;
      }
      &:not(:first-child) {
        margin-top: 50px;
      }
    }
  }
}
</style>
