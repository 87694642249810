<template>
  <div
    :class="[
      'main-container',
      {
        'main-container-full-height': burgerOpen,
      },
    ]"
  >
    <router-view />
    <SuccessModal class="main-container-modal" v-if="isOpen" />
  </div>
</template>

<script>
import SuccessModal from "@/components/SuccessModal.vue";

export default {
  name: "App",
  components: { SuccessModal },
  computed: {
    isOpen() {
      return this.$store.getters.getIsOpen;
    },
    burgerOpen() {
      return this.$store.getters.getBurger;
    },
  },
  created() {
    const locale = localStorage.getItem("locale");
    if (locale) {
      this.$i18n.locale = locale;
    }
  },
};
</script>
<style lang="scss">
.main-container {
  position: relative;
  &-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-full-height {
    height: 100vh;
    overflow: hidden;
  }
}
</style>
